<template>
    <div class="qr_view_wrapper">
        <lottie-animation
            ref="anim"
            :animationData="require('@/assets/loaders/qr_loader.json')"
            :loop="true"
        />
    </div>
</template>

<script>
export default {
    name: "QrRedirectView",

    props: {
        keyword: {
            type: String,
            required: true,
        },
    },

    methods: {
        // Start:: Get Restaurant Id
        getRestaurantId() {
            // Start:: Send Request
            this.$axios({
                method: "GET",
                url: `api/qr`,
                params: {
                    link: this.keyword,
                },
            })
                .then((res) => {
                    let restaurantId = res.data.data.id;
                    this.$router.replace(`/restaurant-details/${restaurantId}`);
                })
                .catch((error) => {
                    this.$router.replace(`/`);
                    if (error.response.status == 401) {
                        this.logout();
                    }
                });
            // End:: Send Request
        },
        // End:: Get Restaurant Id
    },

    created() {
        // Start:: Fire Methods
        this.getRestaurantId();
        // End:: Fire Methods
    },
};
</script>

<style lang="scss" scoped>
.qr_view_wrapper {
    height: 100vh;
    @include flexCenterAlignment;
}
</style>
