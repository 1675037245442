<template>
    <div>
        <!-- START:: FORM TITLE -->
        <div class="form_title_wrapper">
            <!-- START:: TITLES -->
            <h2 class="form_title">{{ $t("TITLES.register") }}</h2>
            <h3 class="form_subtitle">{{ $t("TITLES.register_welcome") }}</h3>
            <!-- END:: TITLES -->
        </div>
        <!-- END:: FORM TITLE -->

        <!-- START:: FORM -->
        <div class="form_wrapper">
            <form class="mt-2" @submit.prevent="validateFormInputs">
                <!-- START:: NAME INPUT GROUP -->
                <div class="input_wrapper">
                    <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('PLACEHOLDERS.user_name')"
                        v-model.trim="data.name"
                    />
                </div>
                <!-- END:: NAME INPUT GROUP -->

                <!-- START:: EMAIL INPUT GROUP -->
                <div class="input_wrapper">
                    <input
                        type="email"
                        class="form-control"
                        :placeholder="$t('PLACEHOLDERS.email')"
                        v-model.trim="data.email"
                    />
                </div>
                <!-- END:: EMAIL INPUT GROUP -->

                <!-- START:: PHONE NUMBER INPUT GROUP -->
                <div class="input_wrapper">
                    <input
                        type="tel"
                        class="form-control"
                        :placeholder="$t('PLACEHOLDERS.phone')"
                        v-model.trim="data.phone"
                    />
                </div>
                <!-- END:: PHONE NUMBER INPUT GROUP -->

                <!-- START:: PASSWORD INPUT GROUP -->
                <div class="input_wrapper password_input_wrapper">
                    <input
                        type="password"
                        class="form-control"
                        :placeholder="$t('PLACEHOLDERS.password')"
                        v-model.trim="data.password"
                    />
                    <button
                        type="button"
                        class="password_visibility_toggler"
                        @click="togglePasswordVisibility"
                    >
                        <i class="fal fa-eye"></i>
                        <i class="fal fa-eye-slash"></i>
                    </button>
                </div>
                <!-- END:: PASSWORD INPUT GROUP -->

                <!-- START:: CONFIRM PASSWORD INPUT GROUP -->
                <div class="input_wrapper password_input_wrapper">
                    <input
                        type="password"
                        class="form-control"
                        :placeholder="$t('PLACEHOLDERS.confirm_password')"
                        v-model.trim="data.confirmPassword"
                    />
                    <button
                        type="button"
                        class="password_visibility_toggler"
                        @click="togglePasswordVisibility"
                    >
                        <i class="fal fa-eye"></i>
                        <i class="fal fa-eye-slash"></i>
                    </button>
                </div>
                <!-- END:: CONFIRM PASSWORD INPUT GROUP -->

                <!-- START:: POLICY TITLE -->
                <p class="policy_title">
                    بمجرد إنشاء الحساب هذا دليل على موافقتك على
                    <button type="button" @click="toggleTermsModal">
                        {{ $t("BUTTONS.terms") }}
                    </button>
                    الخاصة بالتطبيق
                </p>
                <!-- END:: POLICY TITLE -->

                <!-- START:: SUBMIT BUTTON WRAPPER -->
                <div class="btn_wrapper">
                    <button>
                        {{ $t("BUTTONS.submit") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BUTTON WRAPPER -->

                <!-- START:: LOGIN ROUTE WRAPPER -->
                <router-link to="/auth/login" class="register_route">
                    <span>{{ $t("BUTTONS.have_account") }}</span>
                    <span> {{ $t("BUTTONS.login") }}</span>
                </router-link>
                <!-- END:: LOGIN ROUTE WRAPPER -->
            </form>
        </div>
        <!-- END:: FORM -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapActions, mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

export default {
    name: "RegisterForm",

    computed: {
        // START:: VUEX GET APP LANG
        ...mapGetters("AppLangModule", ["getAppLocale"]),
        // END:: VUEX GET APP LANG
    },

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: MODALS HANDLING DATA
            termsModalIsOpen: false,
            // END:: MODALS HANDLING DATA

            // START:: COUNTRIES KEYS MENU HANDLING DATA
            countryKiesMenuIsOpen: false,
            // END:: COUNTRIES KEYS MENU HANDLING DATA

            // START:: DATA
            data: {
                name: null,
                email: null,
                phone: null,
                password: null,
                confirmPassword: null,
            },
            // END:: DATA
        };
    },

    methods: {
        // START:: VUEX SET AUTHENTICATED USER DATA
        ...mapActions("AuthenticationModule", ["setAuthenticatedUserData"]),
        // END:: VUEX SET AUTHENTICATED USER DATA

        // START:: TOGGLE TERMS MODAL METHOD
        toggleTermsModal() {
            this.termsModalIsOpen = !this.termsModalIsOpen;
        },
        // END:: TOGGLE TERMS MODAL METHOD

        // isLetter(e) {
        //     // console.log( e );
        //     if (this.getAppLocale == 'en') {
        //         let char = String.fromCharCode(e.keyCode);
        //         if (/^[A-Za-z ]+$/.test(char)) return true;
        //         else e.preventDefault();
        //     } else {
        //         let char = String.fromCharCode(e.keyCode);
        //         if (/^[\u0621-\u064A ]+$/.test(char)) return true;
        //         else e.preventDefault();
        //     }
        // },

        // START:: TOGGLE PASSWORD VISIBILITY METHOD
        togglePasswordVisibility(e) {
            let passwordElement = e.currentTarget.parentElement.children[0];
            let passwordTogglerBtn = e.currentTarget;
            if (passwordElement.type == "password") {
                passwordElement.type = "text";
                passwordTogglerBtn.classList.add("password_is_visible");
            } else if (passwordElement.type == "text") {
                passwordElement.type = "password";
                passwordTogglerBtn.classList.remove("password_is_visible");
            }
        },
        // END:: TOGGLE PASSWORD VISIBILITY METHOD

        // START:: VALIDATE FORM INPUTS
        validateFormInputs() {
            this.isWaitingRequest = true;

            if (!this.data.name) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.name"),
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.data.email) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.email"),
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.data.phone) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.phone"),
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.data.password) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.password"),
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (this.data.password.length < 6) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.password_length"),
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.data.confirmPassword) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.password"),
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (this.data.confirmPassword.length < 6) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.password_length"),
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (this.data.password != this.data.confirmPassword) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.not_equal_passwords"),
                    messageSize: "22",
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                this.submitForm();
            }
        },
        // END:: VALIDATE FORM INPUTS

        // START:: SUBMIT FORM
        submitForm() {
            this.isWaitingRequest = true;

            const request_data = new FormData();

            // START:: APPEND GENERAL DATA
            request_data.append("username", this.data.name);
            request_data.append("email", this.data.email);
            request_data.append("phone", this.data.phone);
            request_data.append("password", this.data.password);
            // END:: APPEND GENERAL DATA

            // START:: SEND REQUEST
            this.$axios({
                method: "POST",
                url: `auth/signup`,
                data: request_data,
            })
                .then((res) => {
                    this.isWaitingRequest = false;
                    this.$iziToast.success({
                        timeout: 2000,
                        message: this.$t("VALIDATION.registered"),
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    this.setAuthenticatedUserData({
                        phone: this.data.phone,
                        verificationCode: res.data.dev_message,
                    });
                    this.$router.replace(
                        "/auth/phone-verification/activate-account"
                    );
                    this.clearFormInputs();
                })
                .catch((err) => {
                    this.isWaitingRequest = false;
                    this.$iziToast.error({
                        timeout: 2000,
                        message: err.response.data.message,
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                });
            // START:: SEND REQUEST
        },
        // END:: SUBMIT FORM

        // START:: CLEAR FORM DATA
        clearFormInputs() {
            this.data.name = null;
            this.data.email = null;
            this.data.phone = null;
            this.data.password = null;
        },
        // END:: CLEAR FORM DATA
    },
};
</script>
