<template>
    <div class="restaurants_section_content_wrapper position-relative">
        <!-- Start:: Title Wrapper -->
        <div class="section_title_wrapper">
            <h3 class="section_title">{{ $t("TITLES.categories") }}</h3>
        </div>
        <!-- End:: Title Wrapper -->

        <!-- Start:: Coming Soon Overlay -->
        <ComingSoonOverlay />
        <!-- End:: Coming Soon Overlay -->

        <!-- Start:: Restaurant Categories Carousel -->
        <div class="restaurant_categories_carousel" dir="ltr" v-if="categories">
            <CategoriesCarousel
                :nav="true"
                :dots="false"
                :items="4"
                :margin="15"
                :loop="false"
                :autoplay="false"
                :autoplayTimeout="4000"
                :dragEndSpeed="1000"
                :smartSpeed="1000"
                :responsive="{
                    0: {
                        items: 4,
                        nav: false,
                    },
                    750: {
                        items: 8,
                        nav: false,
                    },
                    1100: {
                        items: 10,
                    },
                }"
            >
                <div class="carousel_item_wrapper">
                    <button class="category_button active_cat">
                        <div class="image_wrapper">
                            <img
                                src="../../assets/media/icons/ui_icons/restaurants_categories/all.svg"
                                alt="category"
                                width="30"
                                height="30"
                            />
                        </div>

                        <div class="cat_name">All</div>
                    </button>
                </div>

                <div
                    class="carousel_item_wrapper"
                    v-for="item in categories"
                    :key="item.id"
                >
                    <button class="category_button">
                        <div class="image_wrapper">
                            <img
                                :src="item.image"
                                :alt="item.name"
                                width="30"
                                height="30"
                            />
                        </div>

                        <div class="cat_name">{{ item.name }}</div>
                    </button>
                </div>
            </CategoriesCarousel>
        </div>
        <!-- End:: Restaurant Categories Carousel -->

        <!-- Start:: Section Content -->
        <div class="row">
            <!-- Start:: Meal Card -->
            <div class="col-md-6 col-xl-4 my-3" v-for="item in 6" :key="item">
                <MealCard />
            </div>
            <!-- End:: Meal Card -->
        </div>
        <!-- End:: Section Content -->
    </div>
</template>

<script>
// Start:: Importing Coming Soon Overlay
import ComingSoonOverlay from "@/components/ui/ComingSoonOverlay.vue";
// End:: Importing Coming Soon Overlay

// Start:: Importing Vuex Helpers
import { mapGetters } from "vuex";
// End:: Importing Vuex Helpers

// Start:: Importing Owl Carousel
import CategoriesCarousel from "vue-owl-carousel";
// End:: Importing Owl Carousel

// Start:: Importing Restaurant Card
import MealCard from "@/components/restaurants/MealCard.vue";
// End:: Importing Restaurant Card

export default {
    name: "MealsCategories",

    components: {
        ComingSoonOverlay,
        CategoriesCarousel,
        MealCard,
    },

    props: {
        categories: {
            type: Array,
            required: false,
        },
    },

    computed: {
        // Start:: Vuex Get App Lang
        ...mapGetters("AppLangModule", ["getAppLocale"]),
        // End:: Vuex Get App Lang
    },
};
</script>
