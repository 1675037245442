<template>
    <nav id="navbar" class="site_navbar">
        <!-- ********** START:: LOGO AND ROUTES ********** -->
        <div class="logo_and_routes_wrapper">
            <!-- START:: LOGO -->
            <router-link to="/" class="logo_wrapper">
                <img
                    :src="
                        getAppTheme == 'light_theme'
                            ? require('@/assets/media/logo/logo.svg')
                            : require('@/assets/media/logo/logo_white.svg')
                    "
                    alt="mjrb"
                    width="60"
                    height="60"
                />
            </router-link>
            <!-- END:: LOGO -->

            <!-- START:: ROUTES -->
            <div class="routes_wrapper">
                <NavbarRoutes
                    listClassName="routes_list"
                    listItemClassName="single_route"
                />
            </div>
            <!-- END:: ROUTES -->
        </div>
        <!-- ********** END:: LOGO AND ROUTES ********** -->

        <!-- ********** START:: NAVBAR ACTION BTNS ********** -->
        <div class="navbar_actions_btns_wrapper">
            <!-- START:: SEARCH FORM -->
            <form>
                <div class="search_input_wrapper">
                    <input
                        type="text"
                        :placeholder="$t('PLACEHOLDERS.search')"
                    />
                    <button type="button" class="search_btn">
                        <img
                            :src="
                                getAppTheme == 'light_theme'
                                    ? require('@/assets/media/icons/ui_icons/search.svg')
                                    : require('@/assets/media/icons/ui_icons/search_white.svg')
                            "
                            alt="search"
                            width="22"
                            height="22"
                        />
                    </button>
                </div>
            </form>
            <!-- END:: SEARCH FORM -->

            <!-- START:: NOTIFICATIONS BUTTON -->
            <a-badge
                :count="4"
                :overflow-count="9"
                v-if="getAuthenticatedUserData.token"
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <router-link
                            to="/notifications"
                            class="notifications_route"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <img
                                :src="
                                    getAppTheme == 'light_theme'
                                        ? require('@/assets/media/icons/ui_icons/bell.svg')
                                        : require('@/assets/media/icons/ui_icons/bell_white.svg')
                                "
                                alt="chat"
                                width="22"
                                height="22"
                            />
                        </router-link>
                    </template>
                    <span class="toolTip">
                        {{ $t("TOOLTIPS.notifications") }}
                    </span>
                </v-tooltip>
            </a-badge>
            <!-- END:: NOTIFICATIONS BUTTON -->

            <!-- START:: CHATS ROUTE -->
            <a-badge
                :count="10"
                :overflow-count="9"
                v-if="getAuthenticatedUserData.token"
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <button class="chats_btn" v-bind="attrs" v-on="on">
                            <img
                                :src="
                                    getAppTheme == 'light_theme'
                                        ? require('@/assets/media/icons/ui_icons/chat.svg')
                                        : require('@/assets/media/icons/ui_icons/chat_white.svg')
                                "
                                alt="chat"
                                width="22"
                                height="22"
                            />
                        </button>
                    </template>
                    <span class="toolTip">
                        {{ $t("TOOLTIPS.chats") }}
                    </span>
                </v-tooltip>
            </a-badge>
            <!-- END:: CHATS ROUTE -->

            <!-- START:: CHANGE THEME BUTTON -->
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <button
                        class="theme_changer"
                        @click="changeAppTheme"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <img
                            :src="
                                getAppTheme == 'light_theme'
                                    ? require('@/assets/media/icons/ui_icons/sun.svg')
                                    : require('@/assets/media/icons/ui_icons/moon.svg')
                            "
                            alt="Theme"
                            width="25"
                            height="25"
                        />
                    </button>
                </template>
                <span class="toolTip">
                    {{ $t("TOOLTIPS.changeTheme") }}
                </span>
            </v-tooltip>
            <!-- END:: CHANGE THEME BUTTON -->

            <!-- START:: LANGUAGE BUTTON -->
            <div class="lang_toggler_wrapper">
                <button
                    class="lang_toggeler_btn"
                    @click="changeAppLanguage('en')"
                    v-if="getAppLocale == 'ar'"
                >
                    <img
                        src="../../assets/media/images/flags/en.png"
                        alt="English"
                        width="40"
                        height="40"
                    />
                </button>
                <button
                    class="lang_toggeler_btn"
                    @click="changeAppLanguage('ar')"
                    v-else
                >
                    <img
                        src="../../assets/media/images/flags/ar.png"
                        alt="Arabic"
                        width="40"
                        height="40"
                    />
                </button>
            </div>
            <!-- END:: LANGUAGE BUTTON -->

            <!-- START:: AUTH ROUTES -->
            <div class="wrapper">
                <!-- <router-link
                    to="/auth/login"
                    class="auth_route"
                    v-if="
                        !getAuthenticatedUserData.token &&
                        !$route.path.includes('auth')
                    "
                >
                    {{ $t("BUTTONS.login") }}
                </router-link> -->

                <a
                    href="https://www.bymjrb.com/provider/auth/register"
                    class="auth_route"
                    v-if="!getAuthenticatedUserData.token"
                >
                    {{ $t("BUTTONS.registerAsProvide") }}
                </a>
            </div>
            <!-- END:: AUTH ROUTES -->

            <!-- START:: PROFILE BUTTON -->
            <div
                class="user_info_btn_content_wrapper"
                v-if="getAuthenticatedUserData.token"
            >
                <button
                    class="user_info_btn"
                    @click.stop="toggleUserOptionsMenu"
                >
                    <div class="img_wrapper">
                        <img
                            src="@/assets/media/images/user_avatar.png"
                            alt="User Image"
                            width="45"
                            height="45"
                        />
                    </div>

                    <div class="info_wrapper">
                        <div class="name">Abdulla Ahmed</div>
                        <div class="phone">+971 2147 8541 212</div>
                    </div>

                    <div
                        class="icon_wrapper"
                        :class="{ active: userOptionsMenuIsOpen }"
                    >
                        <img
                            :src="
                                getAppTheme == 'light_theme'
                                    ? require('@/assets/media/icons/ui_icons/arrow_down.svg')
                                    : require('@/assets/media/icons/ui_icons/arrow_down_white.svg')
                            "
                            alt="User Image"
                            width="50"
                            height="50"
                        />
                    </div>
                </button>

                <transition name="fadeInUp">
                    <div
                        class="user_info_menu_wrapper"
                        v-if="userOptionsMenuIsOpen"
                    >
                        <ul class="user_options_menu">
                            <li
                                class="menu_item"
                                @click.stop="toggleUserOptionsMenu"
                            >
                                <router-link to="/profile">
                                    {{ $t("NAVBAR.myProfile") }}
                                </router-link>
                            </li>

                            <li
                                class="menu_item"
                                @click.stop="toggleUserOptionsMenu"
                            >
                                <router-link to="/settings">
                                    {{ $t("NAVBAR.settings") }}
                                </router-link>
                            </li>

                            <li
                                class="menu_item"
                                @click.stop="
                                    toggleUserOptionsMenu();
                                    logout();
                                "
                            >
                                <button>
                                    {{ $t("NAVBAR.logout") }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </transition>
            </div>
            <!-- END:: PROFILE BUTTON -->

            <!-- START:: SMALL SCREENS NAVBAR MENU BUTTON -->
            <button
                class="small_screens_navbar_toggeler"
                @click="toggleSmallScreensNavBar"
            >
                <i class="fad fa-bars"></i>
            </button>
            <!-- END:: SMALL SCREENS NAVBAR MENU BUTTON -->
        </div>
        <!-- ********** END:: NAVBAR ACTION BTNS ********** -->

        <!-- ********** Start:: Small Screens Navbar ********** -->
        <div
            class="small_screens_navbar_wrapper"
            :class="{ active: smallScreensNavbarIsOpen }"
        >
            <!-- START:: CLOSE BUTTON -->
            <button class="close_btn" @click="toggleSmallScreensNavBar">
                <i class="fal fa-times"></i>
            </button>
            <!-- END:: CLOSE BUTTON -->

            <!-- START:: LINKS -->

            <NavbarRoutes
                listClassName="small_screens_links"
                viewPort="responsive"
                @click="toggleSmallScreensNavBar"
            />
            <!-- END:: LINKS -->
        </div>
        <!-- ********** End:: Small Screens Navbar ********** -->
    </nav>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING ROUTES
import NavbarRoutes from "./NavbarRoutes.vue";
// END:: IMPORTING ROUTES

export default {
    name: "TheHeader",

    components: {
        NavbarRoutes,
    },

    computed: {
        // START:: VUEX GET APP THEME
        ...mapGetters("AppThemeModule", ["getAppTheme"]),
        // END:: VUEX GET APP THEME

        // START:: VUEX GET APP LANG
        ...mapGetters("AppLangModule", ["getAppLocale"]),
        // END:: VUEX GET APP LANG

        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA

        // START:: VUEX GET NOTIFICATIONS DATA
        ...mapGetters("NotificationsModule", ["notificationsData"]),
        // END:: VUEX GET NOTIFICATIONS DATA
    },

    data() {
        return {
            // START:: SMALL SCREENS NAVBAR APPERANCE DATA
            smallScreensNavbarIsOpen: false,
            // END:: SMALL SCREENS NAVBAR APPERANCE DATA

            // START:: USER OPTIONS APPERANCE DATA
            userOptionsMenuIsOpen: false,
            // END:: USER OPTIONS APPERANCE DATA

            // START:: NOTIFICATIONS MENU APPERANCE DATA
            notificationsMenuIsOpen: false,
            // END:: NOTIFICATIONS MENU APPERANCE DATA

            // START:: NOTIFICATIONS MENU APPERANCE DATA
            searchFormIsOpen: false,
            // END:: NOTIFICATIONS MENU APPERANCE DATA
        };
    },

    methods: {
        // START:: VUEX  APP THEME ACTIONS
        ...mapActions("AppThemeModule", ["changeAppTheme"]),
        // END:: VUEX APP THEME ACTIONS

        // START:: VUEX SET APP LANG
        ...mapActions("AppLangModule", ["changeAppLanguage"]),
        // END:: VUEX SET APP LANG

        // START:: VUEX NOTIFICATIONS ACTIONS
        ...mapActions("NotificationsModule", [
            "getNotifications",
            "deleteNotification",
        ]),
        // END:: VUEX NOTIFICATIONS ACTIONS

        // START:: VUEX  AUTH ACTIONS
        ...mapActions("AuthenticationModule", ["logout"]),
        // END:: VUEX AUTH ACTIONS

        // START:: TOGGLE SMALL SCREENS NAVBAR
        toggleSmallScreensNavBar() {
            this.smallScreensNavbarIsOpen = !this.smallScreensNavbarIsOpen;
            // this.searchIsOpen = false;
            // this.profileMenuIsOpen = false;
            // this.notificationsMenuIsOpen = false;
        },
        // END:: TOGGLE SMALL SCREENS NAVBAR

        // START:: TOGGLE NOTIFICATIONS MENU
        toggleNotificationsMenu() {
            this.notificationsMenuIsOpen = !this.notificationsMenuIsOpen;
            this.smallScreensNavbarIsOpen = false;
        },
        // END:: TOGGLE NOTIFICATIONS MENU

        // START:: TOGGLE USER OPTIONS MENU
        toggleUserOptionsMenu() {
            this.userOptionsMenuIsOpen = !this.userOptionsMenuIsOpen;
        },
        // END:: TOGGLE USER OPTIONS MENU

        // START:: TOGGLE SEARCH FORM
        toggleSearchForm() {
            this.searchFormIsOpen = !this.searchFormIsOpen;
        },
        // END:: TOGGLE SEARCH FORM
    },

    created() {
        window.addEventListener("click", () => {
            this.userOptionsMenuIsOpen = false;
            this.notificationsMenuIsOpen = false;
        });
    },
};
</script>
