<template>
    <ul :class="listClassName">
        <!-- eslint-disable  -->
        <li 
            :class="listItemClassName"
            v-for=" route in routes "
            :key=" route.id "
            v-if=" 
                (route.authRequired == false || route.authRequired == userIsAuthed) && 
                ( route.viewPort == 'all_screens' || route.viewPort == viewPort )
            "
        >
            <router-link :to="route.path" :disabled="route.path != '/' ">
                {{ route.name }}
            </router-link>
        </li>
        <!-- eslint-enable -->

        <!-- Start:: Auth Route -->
        <!-- <li
            v-if="
                !getAuthenticatedUserData.token &&
                viewPort == 'responsive' &&
                !$route.path.includes('auth')
            "
        >
            <router-link to="/auth/login" class="auth_route">
                {{ $t("BUTTONS.login") }}
            </router-link>
        </li> -->

        <li
            v-if="
                !getAuthenticatedUserData.token &&
                viewPort == 'responsive' &&
                !$route.path.includes('auth')
            "
        >
            <a
                href="https://develobug.com/mjrb-provider/auth/register"
                class="auth_route"
                v-if="!getAuthenticatedUserData.token"
            >
                {{ $t("BUTTONS.registerAsProvide") }}
            </a>
        </li>
        <!-- End:: Auth Route -->

        <!-- Start:: Logout Button -->
        <li v-if="getAuthenticatedUserData.token && viewPort == 'responsive'">
            <button class="logout_btn">
                {{ $t("BUTTONS.logout") }}
            </button>
        </li>
        <!-- End:: Logout Button -->
    </ul>
</template>

<script>
// Start:: Importing Vuex Helpers
import { mapGetters } from "vuex";
// End:: Importing Vuex Helpers

export default {
    name: "NavbarRoutes",

    props: {
        listClassName: {
            type: String,
            required: true,
        },
        listItemClassName: {
            type: String,
            required: false,
        },
        viewPort: {
            type: String,
            required: false,
        },
    },

    computed: {
        // Start:: Vuex Get Authenticated User Data
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // End:: Vuex Get Authenticated User Data

        // Start:: Check Auth Status
        userIsAuthed() {
            return this.getAuthenticatedUserData.token ? true : false;
        },
        // End:: Check Auth Status
    },

    data() {
        return {
            // Start:: Routs Data
            routes: [
                {
                    id: 1,
                    path: "/",
                    name: this.$t("NAVBAR.home"),
                    authRequired: false,
                    viewPort: "all_screens",
                },
                {
                    id: 2,
                    path: "/categories",
                    name: this.$t("NAVBAR.categories"),
                    authRequired: false,
                    viewPort: "all_screens",
                },
                {
                    id: 3,
                    path: "/my-order",
                    name: this.$t("NAVBAR.myOrder"),
                    authRequired: true,
                    viewPort: "all_screens",
                },
                {
                    id: 4,
                    path: "/stories",
                    name: this.$t("NAVBAR.stories"),
                    authRequired: false,
                    viewPort: "all_screens",
                },
                {
                    id: 5,
                    path: "/restaurants",
                    name: this.$t("NAVBAR.restaurants"),
                    authRequired: true,
                    viewPort: "all_screens",
                },
                {
                    id: 6,
                    path: "/offers",
                    name: this.$t("NAVBAR.offers"),
                    authRequired: true,
                    viewPort: "all_screens",
                },
                {
                    id: 7,
                    path: "/contact-us",
                    name: this.$t("NAVBAR.contactUs"),
                    authRequired: false,
                    viewPort: "all_screens",
                },
                {
                    id: 8,
                    path: "/about-us",
                    name: this.$t("FOOTER.aboutUs"),
                    authRequired: false,
                    viewPort: "responsive",
                },
                {
                    id: 9,
                    path: "/services",
                    name: this.$t("FOOTER.services"),
                    authRequired: false,
                    viewPort: "responsive",
                },
                {
                    id: 10,
                    path: "/faqs",
                    name: this.$t("FOOTER.faqs"),
                    authRequired: false,
                    viewPort: "responsive",
                },
                {
                    id: 11,
                    path: "/Police",
                    name: this.$t("FOOTER.privacy"),
                    authRequired: false,
                    viewPort: "responsive",
                },
                {
                    id: 12,
                    path: "/terms",
                    name: this.$t("FOOTER.terms"),
                    authRequired: false,
                    viewPort: "responsive",
                },
            ],
            // End:: Routs Data
        };
    },
};
</script>
