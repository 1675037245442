var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stories_wrapper position-relative"},[_c('ComingSoonOverlay'),_c('div',{staticClass:"section_title_wrapper"},[_c('h3',{staticClass:"section_title"},[_vm._v(_vm._s(_vm.$t("TITLES.trendingStories")))])]),_c('div',{staticClass:"stories_carousel",attrs:{"dir":"ltr"}},[_c('StoriesCarousel',{attrs:{"nav":true,"dots":false,"items":4,"margin":15,"loop":false,"autoplay":false,"autoplayTimeout":4000,"dragEndSpeed":1000,"smartSpeed":1000,"responsive":{
                0: {
                    margin: 8,
                    items: 2,
                    nav: false,
                },
                750: {
                    items: 5,
                    nav: false,
                },
                1100: {
                    items: 6,
                },
            }}},_vm._l((10),function(item){return _c('div',{key:item,staticClass:"carousel_item_wrapper"},[_c('button',{staticClass:"story_btn"},[_c('img',{staticClass:"story_image",attrs:{"src":require("@/assets/media/images/story.svg"),"alt":"story","width":"100","height":"220"}})])])}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }