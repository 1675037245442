export default {
    // START:: SET CHATS LIST DATA
    setChatsListData(state, payload) {
        state.chatsList = payload;
    },
    // END:: SET CHATS LIST DATA

    // START:: SET CHAT LIST PAGENATION
    setChatListPagenation(state, payload) {
        state.paginations.previousPageLink = payload.previousPageLink;
        state.paginations.nextPageLink = payload.nextPageLink;
        state.paginations.lastPage = payload.lastPage;
        state.paginations.itemsPerPage = payload.itemsPerPage;
    },
    // END:: SET CHAT LIST PAGENATION

    // START:: SET SINGLE CHAT DATA
    setSingleChatData(state, payload) {
        state.singleChat = payload;
    },
    // END:: SET SINGLE CHAT DATA
};
