<template>
    <div class="image_modal_content_wrapper" v-if="imageSrc">
        <v-dialog
            v-model="modalIsActive"
            @click:outside="toggleModal"
            max-width="800px"
        >
            <v-card>
                <div class="image_wrapper">
                    <img :src="imageSrc" alt="image" />
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "ImageModal",
    emits: ["toggleModal"],
    props: {
        modalIsActive: {
            type: Boolean,
            required: true,
            default: false,
        },
        imageSrc: {
            required: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        toggleModal() {
            this.$emit("toggleModal");
        },
    },
};
</script>

<style lang="scss">
.v-dialog {
    width: unset;
    .v-card {
        box-shadow: none;
        padding: 0;
        .image_wrapper {
            background-color: var(--theme_second_bg_clr);
            padding: 20px;
            img {
                margin: 0;
                width: 500px;
                height: 500px;
                object-fit: cover;
                @include borderRadius(6px);
            }
        }
    }
}

@media (max-width: 840px) {
    .v-dialog {
        .v-card {
            .image_wrapper {
                img {
                    width: 100%;
                }
            }
        }
    }
}
</style>
