import chatMutations from "./mutations";
import chatActions from "./actions.js";
import chatGetters from "./getters.js";

export default {
    namespaced: true,
    state: {
        // START:: CHAT DATA
        chatsList: null,
        singleChat: null,
        // END:: CHAT DATA

        // START:: PAGINATION DATA
        paginations: {
            currentPage: 1,
            lastPage: 15,
            itemsPerPage: 3,
            previousPageLink: null,
            nextPageLink: null,
        },
        // END:: PAGINATION DATA
    },
    mutations: chatMutations,
    actions: chatActions,
    getters: chatGetters,
};
