// import iziToast from "izitoast";
// import i18n from "@/i18n.js";

// START:: IMPORTING AXIOS
import axios from "axios";
// END:: IMPORTING AXIOS

export default {
    // START:: GET CHATS LIST
    getChatsList(context) {
        // ********** START:: SEND REQUEST ********** //
        axios({
            method: "GET",
            url: `chats`,
            params: {
                page: context.state.paginations.currentPage,
            },
        })
            .then((res) => {
                context.commit("setChatsListData", res.data.data);

                // START:: SET PAGENATION DATA
                context.commit("setChatListPagenation", {
                    previousPageLink: res.data.links.prev,
                    nextPageLink: res.data.links.next,
                    lastPage: res.data.meta.last_page,
                    itemsPerPage: res.data.meta.per_page,
                });
                // END:: SET PAGENATION DATA
            })
            .catch((error) => {
                console.error(error.response.data.message);
            });
        // ********** END:: SEND REQUEST ********** //
    },
    // END:: GET CHATS LIST

    // START:: PAGINATION SET CHATS LIST DATA
    setChatsListByPagination(context, payload) {
        let pageNumber = payload.selectedPage
            ? payload.selectedPage
            : payload.pageLink.slice(-1);

        // ********** START:: SEND REQUEST ********** //
        axios({
            method: "GET",
            url: `chats`,
            params: {
                page: context.state.paginations.currentPage,
            },
        })
            .then((res) => {
                context.commit("setChatsListData", res.data.data);

                // START:: SET PAGENATION DATA
                context.commit("setChatListPagenation", {
                    previousPageLink: res.data.links.prev,
                    nextPageLink: res.data.links.next,
                    lastPage: res.data.meta.last_page,
                    itemsPerPage: res.data.meta.per_page,
                });
                // END:: SET PAGENATION DATA
            })
            .catch((error) => {
                console.error(error.response.data.message);
            });
        // ********** END:: SEND REQUEST ********** //

        // START:: SEND GET REQUEST
        axios({
            method: "GET",
            url: `filter`,
            params: { page: pageNumber },
        })
            .then((res) => {
                // START:: SET SEARCH RESULT DATA
                context.commit("setSearchResult", res.data.data);
                // END:: SET SEARCH RESULT DATA

                // START:: SET PAGENATION DATA
                context.commit("setSearchPagenation", {
                    previousPageLink: res.data.links.prev,
                    nextPageLink: res.data.links.next,
                    lastPage: res.data.meta.last_page,
                    itemsPerPage: res.data.meta.per_page,
                });
                // END:: SET PAGENATION DATA
            })
            .catch((err) => {
                console.log(err.response.data.message);
            });
        // END:: SEND GET REQUEST
    },
    // END:: PAGINATION SET SEARCH LIST DATA

    // START:: GET SINGLE CHAT
    getSingleChat(context, payload) {
        // ********** START:: SEND REQUEST ********** //
        axios({
            method: "GET",
            url: `chats/${payload.productId}/${payload.receiverId}`,
        })
            .then((res) => {
                context.commit("setSingleChatData", res.data.data);
            })
            .catch((error) => {
                console.error(error.response.data.message);
            });
        // ********** END:: SEND REQUEST ********** //
    },
    // END:: GET SINGLE CHAT

    // START:: SEND MESSAGE
    sendMessage(context, payload) {
        const request_data = new FormData();
        // ********** START:: APPEND DATA ********** //
        request_data.append("product_id", payload.productId);
        request_data.append("receiver_id", payload.receiverId);
        request_data.append("message_type", payload.messageType);
        request_data.append("message", payload.messageContent);
        // ********** END:: APPEND DATA ********** //

        // ********** START:: SEND REQUEST ********** //
        axios({
            method: "POST",
            url: `chats`,
            data: request_data,
        })
            .then(() => {
                context.dispatch("getChatsList");
                context.dispatch("getSingleChat", {
                    productId: payload.productId,
                    receiverId: payload.receiverId,
                });
            })
            .catch(() => {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.login_failed"),
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
            });
        // ********** END:: SEND REQUEST ********** //
    },
    // END:: SEND MESSAGE
};
