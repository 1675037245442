<template>
    <div class="restaurant_image_menu_wrapper" v-if="menuImages">
        <div class="row" dir="ltr">
            <!-- Start:: Menu Image Wrapper -->
            <div
                class="col-11 col-lg-4 col-xl-4 my-3"
                v-for="(thumb, thumbIndex) in menuImages"
                :key="thumbIndex"
                @click="index = thumbIndex"
            >
                <img
                    class="menu_image"
                    :src="thumb.image"
                    alt="menu image"
                    width="350"
                    height="450"
                />
            </div>
            <!-- End:: Menu Image Wrapper -->
        </div>

        <!-- Start:: Light Gallery -->
        <LightGallery
            dir="ltr"
            :images="menuImagesSrc"
            :index="index"
            @close="index = null"
        />
        <!-- End:: Light Gallery -->
    </div>
</template>

<script>
import { LightGallery } from "vue-light-gallery";

export default {
    name: "RestaurantImagesMenu",

    components: {
        LightGallery,
    },

    props: {
        menuImages: {
            type: Array,
            required: true,
        },
    },

    computed: {
        menuImagesSrc() {
            let srcsArray = [];
            this.menuImages.forEach((item) => {
                srcsArray.push(item.image);
            });
            return srcsArray;
        },
    },

    data() {
        return {
            index: null,
        };
    },
};
</script>
