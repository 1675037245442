<template>
    <div class="app_structure_wrapper auth_wrapper">
        <!-- START:: HEADER -->
        <TheHeader />
        <!-- END:: HEADER -->

        <!-- START:: HOME CONTENT WRAPPER -->
        <div class="row justify-content-center">
            <!-- ********** START:: LARGE CONTENT WRAPPER ********** -->
            <div
                id="large_content_wrapper"
                :class="isReSized == false ? 'col-md-8' : 'col-md-7'"
            >
                <div class="content_wrapper_card">
                    <transition name="fadeInUp">
                        <!-- START:: LARGE CONTENT WRAPPER ROUTER VIEW -->
                        <router-view name="large_view" />
                        <!-- END:: LARGE CONTENT WRAPPER ROUTER VIEW -->
                    </transition>
                </div>
            </div>
            <!-- ********** END:: LARGE CONTENT WRAPPER ********** -->

            <!-- ********** START:: SMALL CONTENT WRAPPER ********** -->
            <div
                id="small_content_wrapper"
                :class="isReSized == false ? 'col-md-4' : 'col-md-5'"
                class="forms_small_content_wrapper px-0"
            >
                <!-- START:: SMALL CONTENT-->
                <div class="content_wrapper_card auth_forms_wrapper">
                    <transition name="fadeInUp">
                        <!-- START:: LARGE CONTENT WRAPPER ROUTER VIEW -->
                        <router-view name="small_view" />
                        <!-- END:: LARGE CONTENT WRAPPER ROUTER VIEW -->
                    </transition>
                </div>
                <!-- END:: SMALL CONTENT-->
            </div>
            <!-- ********** END:: SMALL CONTENT WRAPPER ********** -->
        </div>
        <!-- END:: HOME CONTENT WRAPPER -->

        <!-- START:: FOOTER -->
        <TheFooter />
        <!-- END:: FOOTER -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING LAYOUTS
import TheHeader from "@/components/layouts/TheHeader.vue";
import TheFooter from "@/components/layouts/TheFooter.vue";
// END:: IMPORTING LAYOUTS

export default {
    name: "Authentication",

    components: {
        TheHeader,
        TheFooter,
    },

    computed: {
        // START:: VUEX GET APP THEME
        ...mapGetters("AppThemeModule", ["getAppTheme"]),
        // END:: VUEX GET APP THEME
    },

    data() {
        return {
            // START:: RESIZE DATA
            isReSized: false,
            // END:: RESIZE DATA
        };
    },

    methods: {
        // START:: RESIZE CONTENT WRAPPERS
        onResize() {
            if (window.innerWidth < 1400) {
                this.isReSized = true;
            }
        },
        // END:: RESIZE CONTENT WRAPPERS
    },

    created() {
        // START:: FIRE METHODS
        this.onResize();
        // END:: FIRE METHODS
    },
};
</script>
