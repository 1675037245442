<template>
    <footer>
        <!-- ********** START:: COPY RIGHTS ********** -->
        <div class="copyrights_content_wrapper">
            <p class="copyrights_text">
                Copyright © {{ currentYear }} <span>MJRB</span>
            </p>
        </div>
        <!-- ********** END:: COPY RIGHTS ********** -->

        <!-- ********** START:: INTERNAL ROUTES ********** -->
        <div class="internal_routes_wrapper">
            <ul class="internal_routes">
                <li class="internal_route_item">
                    <router-link to="/about-us" disabled>
                        {{ $t("FOOTER.aboutUs") }}
                    </router-link>
                </li>

                <li class="internal_route_item">
                    <router-link to="/terms" disabled>
                        {{ $t("FOOTER.terms") }}
                    </router-link>
                </li>

                <li class="internal_route_item">
                    <router-link to="/Police" disabled>
                        {{ $t("FOOTER.privacy") }}
                    </router-link>
                </li>

                <li class="internal_route_item">
                    <router-link to="/services" disabled>
                        {{ $t("FOOTER.services") }}
                    </router-link>
                </li>

                <li class="internal_route_item">
                    <router-link to="/faqs" disabled>
                        {{ $t("FOOTER.faqs") }}
                    </router-link>
                </li>
            </ul>
        </div>
        <!-- ********** END:: INTERNAL ROUTES ********** -->

        <!-- ********** START:: SOCIAL LINKS ********** -->
        <div class="social_links_wrapper">
            <ul class="social_links_list">
                <li class="social_link_item">
                    <a href="#" target="_blanck">
                        <img
                            src="@/assets/media/icons/social/facebook.svg"
                            alt="Facebook"
                            width="22"
                            height="22"
                        />
                    </a>
                </li>

                <li class="social_link_item">
                    <a href="#" target="_blanck">
                        <img
                            src="@/assets/media/icons/social/Twitter.svg"
                            alt="Twitter"
                            width="22"
                            height="22"
                        />
                    </a>
                </li>

                <li class="social_link_item">
                    <a href="#" target="_blanck">
                        <img
                            src="@/assets/media/icons/social/instagram.svg"
                            alt="Instagram"
                            width="22"
                            height="22"
                        />
                    </a>
                </li>

                <li class="social_link_item">
                    <a href="#" target="_blanck">
                        <img
                            src="@/assets/media/icons/social/youtube.svg"
                            alt="Youtube"
                            width="22"
                            height="22"
                        />
                    </a>
                </li>
            </ul>
        </div>
        <!-- ********** END:: SOCIAL LINKS ********** -->
    </footer>
</template>

<script>
export default {
    name: "TheFooter",

    data() {
        return {
            // START:: CURRENT YEAR DATA
            currentYear: new Date().getFullYear(),
            // END:: CURRENT YEAR DATA
        };
    },
};
</script>
