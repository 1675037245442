<template>
    <div class="cart_content_wrapper position-relative">
        <!-- Start:: Coming Soon Overlay -->
        <ComingSoonOverlay />
        <!-- End:: Coming Soon Overlay -->
        <img
            class="coming_soon_img"
            src="../../assets/media/images/coming_soon.png"
            width="200"
            height="350"
            style="opacity: 0"
        />
    </div>
</template>

<script>
// Start:: Importing Coming Soon Overlay
import ComingSoonOverlay from "@/components/ui/ComingSoonOverlay.vue";
// End:: Importing Coming Soon Overlay

export default {
    name: "TheCart",

    components: {
        ComingSoonOverlay,
    },
};
</script>
