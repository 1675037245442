import AuthenticationMutations from "./mutations.js";
import AuthenticationActions from "./actions.js";
import AuthenticationGetters from "./getters.js";

export default {
    namespaced: true,

    state: {
        // START:: AUTHENTICATED USER DATA
        userId: localStorage.getItem("mjrb_app_user_id"),
        userType: localStorage.getItem("mjrb_app_user_type"),
        userToken: localStorage.getItem("mjrb_app_user_token"),
        userName: localStorage.getItem("mjrb_app_user_name"),
        userAvatar: localStorage.getItem("mjrb_app_user_avatar"),
        userPhone: localStorage.getItem("mjrb_app_user_phone"),
        verificationCode: localStorage.getItem(
            "mjrb_app_user_verification_code"
        ),
        // END:: AUTHENTICATED USER DATA
    },

    mutations: AuthenticationMutations,
    actions: AuthenticationActions,
    getters: AuthenticationGetters,
};
