var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app_structure_wrapper"},[_c('TheHeader'),_c('div',{staticClass:"row justify-content-center"},[_c('div',{class:_vm.isReSized == false ? 'col-lg-8' : 'col-lg-7',attrs:{"id":"large_content_wrapper"}},[_c('div',{staticClass:"content_wrapper_card"},[_c('transition',{attrs:{"name":"fadeInUp"}},[_c('router-view',{attrs:{"name":"large_view"}})],1)],1)]),_c('div',{staticClass:"position-relative px-0",class:_vm.isReSized == false ? 'col-lg-4' : 'col-lg-5',attrs:{"id":"small_content_wrapper"}},[_c('div',{staticClass:"small_content_upper_par_wrapper"},[_c('router-link',{staticClass:"upper_par_item",attrs:{"to":"/cart","disabled":!_vm.getAuthenticatedUserData.token ? 'disabled' : ''}},[_c('img',{attrs:{"src":_vm.getAppTheme == 'light_theme'
                                ? require('@/assets/media/icons/ui_icons/cart.svg')
                                : require('@/assets/media/icons/ui_icons/cart_white.svg'),"alt":"cart","width":"26","height":"26"}}),_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.cart"))+" ")])]),_c('router-link',{staticClass:"upper_par_item",attrs:{"to":"/wishlist","disabled":!_vm.getAuthenticatedUserData.token ? 'disabled' : ''}},[_c('img',{attrs:{"src":_vm.getAppTheme == 'light_theme'
                                ? require('@/assets/media/icons/ui_icons/heart.svg')
                                : require('@/assets/media/icons/ui_icons/heart_white.svg'),"alt":"heart","width":"26","height":"26"}}),_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.wishlist"))+" ")])]),_c('router-link',{staticClass:"upper_par_item",attrs:{"to":"/addresses","disabled":!_vm.getAuthenticatedUserData.token ? 'disabled' : ''}},[_c('img',{attrs:{"src":_vm.getAppTheme == 'light_theme'
                                ? require('@/assets/media/icons/ui_icons/location.svg')
                                : require('@/assets/media/icons/ui_icons/location_white.svg'),"alt":"location","width":"26","height":"26"}}),_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.addresses"))+" ")])]),_c('router-link',{staticClass:"upper_par_item",attrs:{"to":"/stories-archive","disabled":!_vm.getAuthenticatedUserData.token ? 'disabled' : ''}},[_c('img',{attrs:{"src":_vm.getAppTheme == 'light_theme'
                                ? require('@/assets/media/icons/ui_icons/box.svg')
                                : require('@/assets/media/icons/ui_icons/box_white.svg'),"alt":"box","width":"26","height":"26"}}),_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.storiesArchive"))+" ")])]),_c('router-link',{staticClass:"upper_par_item",attrs:{"to":"/reservations","disabled":!_vm.getAuthenticatedUserData.token ? 'disabled' : ''}},[_c('img',{attrs:{"src":_vm.getAppTheme == 'light_theme'
                                ? require('@/assets/media/icons/ui_icons/table.svg')
                                : require('@/assets/media/icons/ui_icons/table_white.svg'),"alt":"table","width":"42","height":"42"}}),_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.reservations"))+" ")])])],1),_c('div',{staticClass:"content_wrapper_card small_content_card"},[_c('transition',{attrs:{"name":"fadeInUp"}},[_c('router-view',{attrs:{"name":"small_view"}})],1)],1)])]),_c('TheFooter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }