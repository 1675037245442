export default {
    // START:: CHATS LIST GETTER
    chatsListData(state) {
        return state.chatsList;
    },
    // END:: CHATS LIST GETTER

    // START:: GET CHATS LIST PAGENATION
    chatsListPagenation(state) {
        return state.paginations;
    },
    // END:: GET CHATS LIST PAGENATION

    // START:: SINGLE CHAT GETTER
    singleChatData(state) {
        return state.singleChat;
    },
    // END:: SINGLE CHAT GETTER
};
