<template>
    <div>
        <!-- START:: PAGE CONTENT -->
        <div class="faqs_page_content_wrapper">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <v-expansion-panels v-if="allFaqsData">
                            <!-- START:: SINGLE QUESTION PANEL -->
                            <v-expansion-panel
                                v-for="item in allFaqsData"
                                :key="item.id"
                            >
                                <!-- ========== START:: QUESTION HEADER ========== -->
                                <v-expansion-panel-header>
                                    {{ item.name }}
                                </v-expansion-panel-header>
                                <!-- ========== END:: QUESTION HEADER ========== -->

                                <!-- ========== START:: QUESTION CONTENT ========== -->
                                <v-expansion-panel-content>
                                    {{ item.desc }}
                                </v-expansion-panel-content>
                                <!-- ========== END:: QUESTION CONTENT ========== -->
                            </v-expansion-panel>
                            <!-- END:: SINGLE QUESTION PANEL -->
                        </v-expansion-panels>
                    </div>
                </div>
            </div>
        </div>
        <!-- END:: PAGE CONTENT -->
    </div>
</template>

<script>
export default {
    name: "FaqsPage",

    data() {
        return {
            // START:: HANDLING LOADER APPERANCE
            isLoading: false,
            // END:: HANDLING LOADER APPERANCE

            // START:: FAQS DATA
            allFaqsData: null,
            // END:: FAQS DATA
        };
    },

    methods: {
        // START:: GET PAGE DATA
        getPageData() {
            // START:: ========== SEND REQUEST ==========
            this.$axios({
                method: "GET",
                url: `faqs`,
            })
                .then((res) => {
                    this.isLoading = false;
                    this.allFaqsData = res.data.data;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.error(error.response.data.message);
                });
            // END:: ========== SEND REQUEST ==========
        },
        // END:: GET PAGE DATA
    },

    created() {
        // START:: FIRE METHODS
        this.getPageData();
        // END:: FIRE METHODS
    },
};
</script>
