<template>
    <div class="restaurant_menu_content_wrapper">
        <!-- Start:: Menu Tabs -->
        <MenuTabs :tabsContent="tabsContent">
            <!-- ******** Start:: Photos Tab ******** -->
            <template #photos>
                <RestaurantImagesMenu
                    :menuImages="restaurantMenuImages"
                    v-if="restaurantMenuImages"
                />
            </template>
            <!-- ******** End:: Photos Tab ******** -->

            <!-- ******** Start:: Online Order Tab ******** -->
            <template #orderOnline>
                <OrderOnlineSection />
            </template>
            <!-- ******** End:: Online Order Tab ******** -->

            <!-- ******** Start:: Pages Tab ******** -->
            <!-- <template #pages>
                <RestaurantBookMenu />
            </template> -->
            <!-- ******** End:: Pages Tab ******** -->
        </MenuTabs>
        <!-- End:: Menu Tabs -->
    </div>
</template>

<script>
// START:: IMPORT TAB COMPONENT
import MenuTabs from "@/components/structure/Tabs.vue";
// END:: IMPORT TAB COMPONENT

// Start:: Importing Restaurant Menu Components
import OrderOnlineSection from "@/components/general/MealsCategories.vue";
import RestaurantImagesMenu from "@/components/restaurants/RestaurantImagesMenu.vue";
// import RestaurantBookMenu from "@/components/restaurants/RestaurantBookMenu.vue";
// End:: Importing Restaurant Menu Components

export default {
    name: "RestaurantMenu",

    components: {
        MenuTabs,
        OrderOnlineSection,
        RestaurantImagesMenu,
        // RestaurantBookMenu,
    },

    data() {
        return {
            // Start:: Loaders Handling Data
            isWaitingRequest: false,
            // End:: Loaders Handling Data

            // Start:: Restaurant Id
            restaurantId: this.$route.params.id,
            // End:: Restaurant Id

            // Start:: Tabs Content
            tabsContent: [
                {
                    tab: this.$t("TABS.photos"),
                    key_name: "photos",
                },
                {
                    tab: this.$t("TABS.orderOnline"),
                    key_name: "orderOnline",
                },
                // {
                //     tab: this.$t("TABS.pages"),
                //     key_name: "pages",
                // },
            ],
            // End:: Tabs Content

            // Start:: Menu Data
            restaurantMenuImages: null,
            // End:: Menu Data
        };
    },

    methods: {
        // Start:: Get Restaurant Menu
        getRestaurantMenuImages() {
            // Start:: Send Request
            this.$axios({
                method: "GET",
                url: `api/image_menu`,
                params: {
                    branch_id: this.restaurantId,
                },
            })
                .then((res) => {
                    this.isWaitingRequest = false;
                    this.restaurantMenuImages = res.data.data;
                })
                .catch((error) => {
                    this.isWaitingRequest = false;
                    this.$message.error(error.response.data.message);
                    if (error.response.status == 401) {
                        this.logout();
                    }
                });
            // End:: Send Request
        },
        // End:: Get Restaurant Menu
    },

    created() {
        // Start:: Fire Methods
        this.getRestaurantMenuImages();
        // End:: Fire Methods
    },
};
</script>
