// START:: IMPORTING AXIOS
import axios from "axios";
// END:: IMPORTING AXIOS

export default {
    // START:: GET COUNTRIES
    getCountries(context) {
        // START:: SEND GET REQUEST
        axios({
            method: "GET",
            url: `countries`,
        })
            .then((res) => {
                context.commit("setCountries", res.data.data);
            })
            .catch((err) => {
                console.error(err.response.data.message);
            });
        // END:: SEND GET REQUEST
    },
    // END:: GET COUNTRIES

    // START:: GET CITIES
    getCityByCountryId(context, payload) {
        // START:: SEND GET REQUEST
        axios({
            method: "GET",
            url: `cities/${payload}`,
        })
            .then((res) => {
                context.commit("setCities", res.data.data);
            })
            .catch((err) => {
                console.error(err.response.data.message);
            });
        // END:: SEND GET REQUEST
    },
    // END:: GET CITIES

    // START:: GET MAIN CATEGORIES
    getMainCategories(context) {
        // START:: SEND GET REQUEST
        axios({
            method: "GET",
            url: `categories`,
        })
            .then((res) => {
                context.commit("setMainCategories", res.data.data);
            })
            .catch((err) => {
                console.error(err.response.data.message);
            });
        // END:: SEND GET REQUEST
    },
    // END:: GET MAIN CATEGORIES

    // START:: GET SUB CATEGORY BY MAIN CATEGORY ID
    getSubCategoryByMainCategoryId(context, payload) {
        // START:: SEND GET REQUEST
        axios({
            method: "GET",
            url: `subCategories/${payload}`,
        })
            .then((res) => {
                context.commit("setSubCategories", res.data.data);
            })
            .catch((err) => {
                console.error(err.response.data.message);
            });
        // END:: SEND GET REQUEST
    },
    // END:: GET SUB CATEGORY BY MAIN CATEGORY ID

    // START:: GET PRODUCT STATUS
    getProductStatuses(context) {
        // START:: SEND GET REQUEST
        axios({
            method: "GET",
            url: `productStatuses`,
        })
            .then((res) => {
                context.commit("setProductStatuses", res.data.data);
            })
            .catch((err) => {
                console.error(err.response.data.message);
            });
        // END:: SEND GET REQUEST
    },
    // END:: GET PRODUCT STATUS

    // START:: GET SHIPPING WAYES
    getShippingWayes(context) {
        // START:: SEND GET REQUEST
        axios({
            method: "GET",
            url: `shippingWays`,
        })
            .then((res) => {
                context.commit("setShippingWayes", res.data.data);
            })
            .catch((err) => {
                console.error(err.response.data.message);
            });
        // END:: SEND GET REQUEST
    },
    // END:: GET SHIPPING WAYES
};
