export default {
    // START:: COUNTRIES GETTER
    countriesData(state) {
        return state.countries;
    },
    // END:: COUNTRIES GETTER

    // START:: CITIES GETTER
    citiesData(state) {
        return state.cities;
    },
    // END:: CITIES GETTER

    // START:: MAIN CATEGORIES GETTER
    mainCategoriesData(state) {
        return state.mainCategories;
    },
    // END:: MAIN CATEGORIES GETTER

    // START:: SUB CATEGORIES GETTER
    subCategoriesData(state) {
        return state.subCategories;
    },
    // END:: SUB CATEGORIES GETTER

    // START:: PRODUCT STATUSES GETTER
    productStatusesData(state) {
        return state.productStatuses;
    },
    // END:: PRODUCT STATUSES GETTER

    // START:: SHIPPING WAYES GETTER
    shippingWayesData(state) {
        return state.shippingWayes;
    },
    // END:: SHIPPING WAYES GETTER
};
