var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"restaurant_stories_wrapper position-relative"},[_c('ComingSoonOverlay'),_c('div',{staticClass:"stories_carousel",attrs:{"dir":"ltr"}},[_c('StoriesCarousel',{attrs:{"nav":true,"dots":false,"items":4,"margin":15,"loop":false,"autoplay":false,"autoplayTimeout":4000,"dragEndSpeed":1000,"smartSpeed":1000,"responsive":{
                0: {
                    items: 2,
                    nav: false,
                },
                750: {
                    items: 3,
                    nav: false,
                },
                900: {
                    items: 4,
                },
                1100: {
                    items: 6,
                },
            }}},[_c('div',{staticClass:"carousel_item_wrapper"},[_c('button',{staticClass:"story_btn"},[_c('img',{staticClass:"story_image",attrs:{"src":require("@/assets/media/images/addStory.svg"),"alt":"addStory","width":"110","height":"230"}})])]),_vm._l((10),function(item){return _c('div',{key:item,staticClass:"carousel_item_wrapper"},[_c('button',{staticClass:"story_btn"},[_c('img',{staticClass:"story_image",attrs:{"src":require("@/assets/media/images/story.svg"),"alt":"story","width":"100","height":"220"}})])])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }