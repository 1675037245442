<template>
    <div>
        <button class="restaurant_card_wrapper" @click="toggleMealModal">
            <!-- Start:: Restaurant Image Wrapper -->
            <div class="restaurant_image_wrapper">
                <img
                    class="restaurant_image"
                    src="@/assets/media/images/restaurants/restaurantImage.png"
                    alt="restaurant name"
                    width="100"
                    height="100"
                />

                <div class="restaurant_image_overlay">
                    <!-- Start:: Wishlist Btn -->
                    <div class="wishlist_btn_wrapper" @click.prevent="">
                        <!-- <button class="wishlist_btn">
                            <img
                                src="@/assets/media/icons/ui_icons/heart_gray.svg"
                                alt="gray heart"
                                width="20"
                                height="20"
                            />
                        </button> -->

                        <button class="wishlist_btn" @click.stop="">
                            <img
                                src="@/assets/media/icons/ui_icons/heart_gold.svg"
                                alt="gray heart"
                                width="20"
                                height="20"
                            />
                        </button>
                    </div>
                    <!-- End:: Wishlist Btn -->

                    <!-- Start:: Category Icon -->
                    <div class="category_icon_wrapper">
                        <img
                            src="@/assets/media/images/restaurants/restaurant_cat.svg"
                            alt="category"
                            width="40"
                            height="40"
                        />
                    </div>
                    <!-- End:: Category Icon -->

                    <!-- Start:: Restaurant Delivery Time -->
                    <div class="restaurant_delivery_time">
                        <h6>30</h6>
                        <h6>Min</h6>
                    </div>
                    <!-- End:: Restaurant Delivery Time -->
                </div>
            </div>
            <!-- End:: Restaurant Image Wrapper -->

            <!-- Start:: Restaurant Data Wrapper -->
            <div class="restaurant_data_wrapper">
                <!-- <div class="category_icon_wrapper">
                    <img
                        src="@/assets/media/images/restaurants/restaurant_cat.svg"
                        alt="category"
                        width="50"
                        height="50"
                    />
                </div> -->

                <div class="restaurant_name_and_meal_name_wrapper">
                    <div class="restaurant_name_and_rate">
                        <h5>Jimmy's Downtown</h5>
                        <h6>
                            <i class="fas fa-star"></i>
                            4.5
                        </h6>
                    </div>

                    <div class="meal_name">
                        <h6>Cheese Pizza</h6>
                    </div>

                    <div class="offer">
                        <span class="offer_badge">
                            {{ $t("STATUS.offer") }}
                        </span>

                        <span class="offer_text">
                            50 % off Use coupon bremer
                        </span>
                    </div>
                </div>

                <div class="add_to_cart_btn_and_price_wrapper">
                    <button class="add_to_cart_btn" @click.prevent="">
                        <i class="fal fa-shopping-cart"></i>
                    </button>

                    <h6 class="price">100 SAR</h6>
                </div>
            </div>
            <!-- End:: Restaurant Data Wrapper -->
        </button>

        <!-- Start:: Meal Meal -->
        <MealModal
            :modalIsActive="mealModalIsActive"
            @toggleModal="toggleMealModal"
        />
        <!-- End:: Meal Meal -->
    </div>
</template>

<script>
// Start:: Importing Modal
import MealModal from "@/components/modals/MealDetailsModal.vue";
// End:: Importing Modal

export default {
    name: "MealCard",

    components: { MealModal },

    data() {
        return {
            // Start:: Modal Control Data
            mealModalIsActive: false,
            // End:: Modal Control Data
        };
    },

    methods: {
        // Start:: Toggle Meal Modal
        toggleMealModal() {
            // this.mealModalIsActive = !this.mealModalIsActive;
        },
        // End:: Toggle Meal Modal
    },
};
</script>
