<template>
    <div class="super_restaurants_section_content_wrapper">
        <!-- Start:: Title Wrapper -->
        <div class="section_title_wrapper">
            <h3 class="section_title">{{ $t("TITLES.SuperRestaurants") }}</h3>

            <span>
                <img
                    src="@/assets/media/icons/mjrb_badge.svg"
                    alt="mjrb badge"
                    width="25"
                    height="25"
                />
            </span>
        </div>
        <!-- End:: Title Wrapper -->

        <!-- Start:: Section Content -->
        <div class="row">
            <!-- Start:: Super Restaurant Card -->
            <div
                class="col-md-6 col-xl-4 my-3"
                v-for="item in restaurants"
                :key="item.id"
            >
                <SuperRestaurantCard :restaurantData="item" />
            </div>
            <!-- End:: Super Restaurant Card -->
        </div>
        <!-- End:: Section Content -->
    </div>
</template>

<script>
// Start:: Importing Restaurant Card
import SuperRestaurantCard from "@/components/restaurants/SuperRestaurantCard.vue";
// End:: Importing Restaurant Card

export default {
    name: "SuperRestaurantsSection",

    components: {
        SuperRestaurantCard,
    },

    props: {
        restaurants: {
            type: Array,
            required: true,
        },
    },
};
</script>
