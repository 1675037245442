<template>
    <div>   
        <!-- START:: BREADCRUMB -->
        <Breadcrumb
            :breadcrumbOptions=" breadcrumbData "
        />
        <!-- END:: BREADCRUMB -->

        <!-- START:: PAGE CONTENT -->
        <div class="chats_home_wrapper">
            <router-view/>
            <!-- <transition mode="fadeInUp">
            </transition> -->
        </div>
        <!-- END:: PAGE CONTENT -->
    </div>
</template>

<script>
// START:: IMPORTING BREADCRUMB
import Breadcrumb from "@/components/structure/TheBreadcrumb.vue";
// END:: IMPORTING BREADCRUMB

export default {
    name: "ChatHome",

    components: {
        Breadcrumb
    },

        data() {
        return {
            // START:: BREADCRUMB DATA
            breadcrumbData: {
                pageTitle: this.$t("TITLES.chat"),
                items: [
                    {
                        text: this.$t("TITLES.home"),
                        disabled: false,
                        href: "/",
                    },
                    {
                        text: this.$t("TITLES.chat"),
                        disabled: true,
                        href: "",
                    },
                ],
            },
            // END:: BREADCRUMB DATA
        }
    },
}
</script>