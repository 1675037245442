import ApiGetsMutations from "./mutations";
import ApiGetsActions from "./actions.js";
import ApiGetsGetters from "./getters.js";

export default {
    namespaced: true,
    state: {
        // START:: COUNTRIES
        countries: null,
        // END:: COUNTRIES

        // START:: CITIES
        cities: [],
        // END:: CITIES

        // START:: MAIN CATEGORIES
        mainCategories: null,
        // END:: MAIN CATEGORIES

        // START:: SUB CATEGORIES
        subCategories: [],
        // END:: SUB CATEGORIES

        // START:: PRODUCT STATUSES
        productStatuses: null,
        // END:: PRODUCT STATUSES

        // START:: SHIPPING WAY
        shippingWayes: null,
        // END:: SHIPPING WAY
    },
    mutations: ApiGetsMutations,
    actions: ApiGetsActions,
    getters: ApiGetsGetters,
};
