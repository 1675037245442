export default {
    // START:: SET COUNTRIES
    setCountries(state, payload) {
        state.countries = payload;
    },
    // END:: SET COUNTRIES

    // START:: SET CITIES
    setCities(state, payload) {
        state.cities = payload;
    },
    // END:: SET CITIES

    // START:: SET MAIN CATEGORIES
    setMainCategories(state, payload) {
        state.mainCategories = payload;
    },
    // END:: SET MAIN CATEGORIES

    // START:: SET SUB CATEGORIES
    setSubCategories(state, payload) {
        state.subCategories = payload;
    },
    // END:: SET SUB CATEGORIES

    // START:: SET PRODUCT STATUSES
    setProductStatuses(state, payload) {
        state.productStatuses = payload;
    },
    // END:: SET PRODUCT STATUSES

    // START:: SET SHIPPING WAYES
    setShippingWayes(state, payload) {
        state.shippingWayes = payload;
    },
    // END:: SET SHIPPING WAYES
};
