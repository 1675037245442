<template>
    <div class="chats_list_wrapper">
        <div class="container" v-if="chatsListData">
            <!-- START:: CHATS LIST -->
            <ul class="chats_list">
                <li
                    class="chat_route_wrapper"
                    v-for="item in chatsListData"
                    :key="item.id"
                >
                    <button
                        class="chat_route_btn"
                        @click="
                            navigateToChat(item.product_id, item.receiver_id)
                        "
                    >
                        <!-- START:: CONVERSATION AVATAR -->
                        <div class="conversation_avatar_wrapper">
                            <img
                                :src="item.product.main_image"
                                :alt="item.product.name"
                                width="90"
                                height="90"
                            />
                        </div>
                        <!-- END:: CONVERSATION AVATAR -->

                        <!-- START:: CONVERSATION DETAILS -->
                        <div class="conversation_details_wrapper">
                            <div class="name_and_last_message_date_wrapper">
                                <h5 class="name">{{ item.product.name }}</h5>
                                <h6 class="last_message_date_wrapper">
                                    {{ item.created_at }}
                                </h6>
                            </div>
                            <h6 class="last_message">
                                {{ item.last_message }}
                            </h6>
                        </div>
                        <!-- END:: CONVERSATION DETAILS -->
                    </button>
                </li>
            </ul>
            <!-- END:: CHATS LIST -->

            <!-- START:: PAGINATION -->
            <div class="pagination_container text-center my-4">
                <v-pagination
                    class="py-0"
                    square
                    v-model="chatsListPagenation.currentPage"
                    :length="chatsListPagenation.lastPage"
                    :total-visible="6"
                    :prev-icon="
                        getAppLocale == 'ar'
                            ? 'fal fa-angle-right'
                            : 'fal fa-angle-left'
                    "
                    :next-icon="
                        getAppLocale == 'ar'
                            ? 'fal fa-angle-left'
                            : 'fal fa-angle-right'
                    "
                    @input="
                        setSearchResultsByPagination({
                            pageLink: null,
                            selectedPage: setChatsListByPagination.currentPage,
                        })
                    "
                    @next="
                        setSearchResultsByPagination({
                            pageLink: setChatsListByPagination.nextPageLink,
                            selectedPage: null,
                        })
                    "
                    @previous="
                        setSearchResultsByPagination({
                            pageLink: setChatsListByPagination.previousPageLink,
                            selectedPage: null,
                        })
                    "
                >
                </v-pagination>
            </div>
            <!-- END:: PAGINATION -->
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS

export default {
    name: "AllChats",

    computed: {
        // START:: VUEX GET APP LANG
        ...mapGetters("AppLangModule", ["getAppLocale"]),
        // END:: VUEX GET APP LANG

        // START:: VUEX CHAT DATA
        ...mapGetters("ChatModule", ["chatsListData", "chatsListPagenation"]),
        // END:: VUEX CHAT DATA
    },

    methods: {
        // START:: VUEX CHAT SEND MESSAGE
        ...mapActions("ChatModule", [
            "getChatsList",
            "setChatsListByPagination",
        ]),
        // END:: VUEX CHAT SEND MESSAGE

        // STAR:: NAVIGATE TO CHAT
        navigateToChat(productId, receiverId) {
            this.$router.push(`/chats/${productId}/${receiverId}`);
        },
        // END:: NAVIGATE TO CHAT
    },

    created() {
        // START:: FIRE METHODS
        this.getChatsList();
        // END:: FIRE METHODS
    },
};
</script>
