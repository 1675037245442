<template>
    <div class="wrapper w-100">
        <!-- START:: CONTENT_WRAPPER -->
        <div class="privacy_policy_page_wrapper">
            <!-- START:: CONTENT -->
            <div class="container">
                <div class="page_content" v-html="terms"></div>
            </div>
            <!-- END:: CONTENT -->
        </div>
        <!-- END:: CONTENT_WRAPPER -->
    </div>
</template>

<script>
export default {
    name: "TermsAndConditions",

    data() {
        return {
            // START:: HANDLING MAIN LOADER APPERANCE
            isLoading: true,
            // END:: HANDLING MAIN LOADER APPERANCE

            // START:: TERMS AND CONDITIONS DATA
            terms: null,
            // END:: TERMS AND CONDITIONS DATA
        };
    },

    methods: {
        // START:: GET PAGE DATA
        getPageData() {
            // START:: ========== SEND REQUEST ==========
            this.$axios({
                method: "GET",
                url: `terms`,
            })
                .then((res) => {
                    this.isLoading = false;
                    this.terms = res.data.data.terms;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.error(error.response.data.message);
                });
            // END:: ========== SEND REQUEST ==========
        },
        // END:: GET PAGE DATA
    },

    created() {
        // START:: FIRE METHODS
        this.getPageData();
        // END:: FIRE METHODS
    },
};
</script>
