<template>
    <div class="mjrb_rating">
        <!-- START:: ZERO STARS -->
        <span v-if="rate == '0'">
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
        </span>
        <!-- END:: ZERO STARS -->

        <!-- START:: ONE STARS -->
        <span v-if="rate == '1'">
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
        </span>
        <!-- END:: ONE STARS -->

        <!-- START:: TWO STARS -->
        <span v-if="rate == '2'">
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
        </span>
        <!-- END:: TWO STARS -->

        <!-- START:: THREE STARS -->
        <span v-if="rate == '3'">
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
        </span>
        <!-- END:: THREE STARS -->

        <!-- START:: FOUR STARS -->
        <span v-if="rate == '4'">
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate_opacity.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
        </span>
        <!-- END:: FOUR STARS -->

        <!-- START:: FIVE STARS -->
        <span v-if="rate == '5'">
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
            <img
                src="@/assets/media/icons/mjrb_rate.svg"
                alt="mjrb rate badge"
                width="22"
                height="22"
            />
        </span>
        <!-- END:: FIVE STARS -->
    </div>
</template>

<script>
export default {
    props: ["rate"],
};
</script>

<style lang="scss" scoped>
.mjrb_rating {
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 7px;
    }
}
</style>
