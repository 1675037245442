<template>
    <div class="coming_soon_overlay_wrapper">
        <img
            src="@/assets/media/images/comingSoonHint.svg"
            alt="ComingSoonHint"
            width="150"
            height="150"
        />
    </div>
</template>

<script>
export default {
    name: "ComingSoonOverlay",
};
</script>
