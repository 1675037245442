export default {
    // START:: SET AUTHENTICATED USER DATA
    setAuthenticatedUserData(state, payload) {
        if (payload.id) {
            state.userId = payload.id;
            localStorage.setItem("mjrb_app_user_id", payload.id);
        }
        if (payload.type) {
            state.userType = payload.type;
            localStorage.setItem("mjrb_app_user_type", payload.type);
        }
        if (payload.token) {
            state.userToken = payload.token;
            localStorage.setItem("mjrb_app_user_token", payload.token);
        }
        if (payload.avatar) {
            state.userAvatar = payload.avatar;
            localStorage.setItem("mjrb_app_user_avatar", payload.avatar);
        }
        if (payload.name) {
            state.userName = payload.name;
            localStorage.setItem("mjrb_app_user_name", payload.name);
        }
        if (payload.phone) {
            state.userPhone = payload.phone;
            localStorage.setItem("mjrb_app_user_phone", payload.phone);
        }
        if (payload.verificationCode) {
            state.verificationCode = payload.verificationCode;
            localStorage.setItem(
                "mjrb_app_user_verification_code",
                payload.verificationCode
            );
        }
    },
    // END:: SET AUTHENTICATED USER DATA

    // START:: DELETE LOCAL STORAGE AUTHED DATA
    deleteAuthenticatedUserData(_, payload) {
        if (payload.id) {
            localStorage.removeItem("mjrb_app_user_id");
        }
        if (payload.type) {
            localStorage.removeItem("mjrb_app_user_type");
        }
        if (payload.token) {
            localStorage.removeItem("mjrb_app_user_token");
        }
        if (payload.avatar) {
            localStorage.removeItem("mjrb_app_user_avatar");
        }
        if (payload.phone) {
            localStorage.removeItem("mjrb_app_user_phone");
        }
        if (payload.verificationCode) {
            localStorage.removeItem("mjrb_app_user_verification_code");
        }
    },
    // END:: DELETE LOCAL STORAGE AUTHED DATA
};
