<template>
    <router-link
        :to="`/restaurant-details/${restaurantData.id}`"
        class="super_restaurant_card_wrapper"
    >
        <!-- Start:: Restaurant Image Wrapper -->
        <div class="restaurant_image_wrapper">
            <img
                class="restaurant_image"
                :src="restaurantData.image"
                :alt="restaurantData.name"
                width="100"
                height="100"
            />

            <div class="restaurant_image_overlay">
                <!-- Start:: Restaurant Delivery Time -->
                <div
                    class="restaurant_delivery_time"
                    v-if="restaurantData.distance"
                >
                    <h6>{{ restaurantData.distance }}</h6>
                    <h6>K.m</h6>
                </div>
                <!-- End:: Restaurant Delivery Time -->
            </div>
        </div>
        <!-- End:: Restaurant Image Wrapper -->

        <!-- Start:: Restaurant Data Wrapper -->
        <div class="restaurant_data_wrapper">
            <div class="restaurant_logo_wrapper">
                <img
                    :src="restaurantData.icon"
                    :alt="restaurantData.name"
                    width="55"
                    height="55"
                />
            </div>

            <div class="restaurant_name_and_category_wrapper">
                <div class="restaurant_name_and_badge">
                    <h5>{{ restaurantData.name }}</h5>
                    <img
                        src="@/assets/media/icons/mjrb_badge.svg"
                        alt="mjrb badge"
                        width="18"
                        height="18"
                        v-if="restaurantData.is_verified"
                    />
                </div>

                <div class="restaurant_category">
                    <h6>{{ restaurantData.desc }}</h6>
                </div>
            </div>
        </div>
        <!-- End:: Restaurant Data Wrapper -->
    </router-link>
</template>

<script>
export default {
    name: "SuperRestaurantCard",

    props: {
        restaurantData: {
            type: Object,
            required: true,
        },
    },
};
</script>
