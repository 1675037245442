var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{class:_vm.listClassName},[_vm._l((_vm.routes),function(route){return ( 
            (route.authRequired == false || route.authRequired == _vm.userIsAuthed) && 
            ( route.viewPort == 'all_screens' || route.viewPort == _vm.viewPort )
        )?_c('li',{key:route.id,class:_vm.listItemClassName},[_c('router-link',{attrs:{"to":route.path,"disabled":route.path != '/'}},[_vm._v(" "+_vm._s(route.name)+" ")])],1):_vm._e()}),(
            !_vm.getAuthenticatedUserData.token &&
            _vm.viewPort == 'responsive' &&
            !_vm.$route.path.includes('auth')
        )?_c('li',[(!_vm.getAuthenticatedUserData.token)?_c('a',{staticClass:"auth_route",attrs:{"href":"https://develobug.com/mjrb-provider/auth/register"}},[_vm._v(" "+_vm._s(_vm.$t("BUTTONS.registerAsProvide"))+" ")]):_vm._e()]):_vm._e(),(_vm.getAuthenticatedUserData.token && _vm.viewPort == 'responsive')?_c('li',[_c('button',{staticClass:"logout_btn"},[_vm._v(" "+_vm._s(_vm.$t("BUTTONS.logout"))+" ")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }