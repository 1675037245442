<template :key="$route.path">
    <div class="chat_content_wrapper">
        <div class="container" v-if="singleChatData">
            <!-- START:: MESSAGES -->
            <div
                class="messages_box"
                v-chat-scroll="{ always: true, smooth: true }"
            >
                <!-- START:: START OF THE CHAT TITLE -->
                <p class="start_of_the_chat">
                    {{ $t("TITLES.start_of_the_chat") }}
                </p>
                <!-- END:: START OF THE CHAT TITLE -->

                <!-- START:: TEXT MESSAGES -->
                <div
                    v-for="message in [...singleChatData].reverse()"
                    :key="message.id"
                    class="message_wrapper"
                    :class="
                        message.message_position == 'me' ? 'sender' : 'receiver'
                    "
                >
                    <p
                        class="text_message_content"
                        v-if="message.message_type == 'text'"
                    >
                        {{ message.message }}
                    </p>

                    <div
                        class="image_message_content"
                        v-else-if="message.message_type == 'image'"
                    >
                        <img :src="message.message" alt="Image Message" />
                    </div>

                    <div class="file_message_content" v-else>
                        <a :href="message.message" target="_blank">
                            <i class="fal fa-file-alt"></i>
                            <span>
                                {{ message.message.substring(0, 25) + "..." }}
                            </span>
                        </a>
                    </div>
                </div>
                <!-- END:: TEXT MESSAGES -->
            </div>
            <!-- END:: MESSAGES -->

            <!-- START:: CHAT INPUT -->
            <form @submit.prevent="sendChatMessage" class="chat_input_wrapper">
                <!-- START:: SELECTED FILE PREVIEW -->
                <transition mode="fadeIn">
                    <div class="selected_file_preview" v-if="filePreviewIsOpen">
                        <p>{{ selectedMediaFile.name }}</p>
                        <button
                            type="button"
                            class="close_file_preview"
                            @click.stop="closeFilePreview"
                        >
                            <i class="fal fa-times"></i>
                        </button>
                    </div>
                </transition>
                <!-- END:: SELECTED FILE PREVIEW -->

                <!-- START:: SEND BUTTON -->
                <div class="send_btn">
                    <button class="send_message_btn">
                        <i class="fal fa-paper-plane"></i>
                    </button>
                </div>
                <!-- END:: SEND BUTTON -->

                <!-- START:: INPUT -->
                <div class="input_wrapper">
                    <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('PLACEHOLDERS.write_here')"
                        v-model.trim="textMessageContent"
                        :disabled="selectedMediaFile.file"
                    />

                    <!-- START:: MEDIA BUTTON -->
                    <!-- <div class="media_buttons_wrapper">
                        <div class="wrapper">
                            <label for="select_media">
                                <i class="fal fa-paperclip"></i>
                            </label>
                            <input
                                id="select_media"
                                type="file"
                                accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .txt"
                                @change="selectMediaFile"
                            />
                        </div>

                        <button type="button" class="select_emoji">
                            <div class="chat-emoji wrapper">
                                <emoji-picker @emoji="append" :search="searchEmoji">
                                    <div
                                        class="emoji-invoker"
                                        slot="emoji-invoker"
                                        slot-scope="{
                                            events: { click: clickEvent },
                                        }"
                                        @click.stop="clickEvent"
                                    >
                                        <i class="fal fa-smile"></i>
                                    </div>
                                    <div
                                        slot="emoji-picker"
                                        slot-scope="{ emojis, insert, display }"
                                    >
                                        <div
                                            class="emoji-picker"
                                            :style="{
                                                top: display.y + 'px',
                                                left: display.x + 'px',
                                            }"
                                        >
                                            <div class="emoji-picker__search">
                                                <input
                                                    type="text"
                                                    v-model="searchEmoji"
                                                    :placeholder="
                                                        $t(
                                                            'PLACEHOLDERS.search_for_emoji'
                                                        )
                                                    "
                                                    v-focus
                                                />
                                            </div>
                                            <div>
                                                <div
                                                    v-for="(
                                                        emojiGroup, category
                                                    ) in emojis"
                                                    :key="category"
                                                >
                                                    <h5>{{ category }}</h5>
                                                    <div class="emojis">
                                                        <span
                                                            v-for="(
                                                                emoji, emojiName
                                                            ) in emojiGroup"
                                                            :key="emojiName"
                                                            @click="insert(emoji)"
                                                            :title="emojiName"
                                                            >{{ emoji }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </emoji-picker>
                            </div>
                        </button>
                    </div> -->
                    <!-- END:: MEDIA BUTTON -->
                </div>
                <!-- END:: INPUT -->
            </form>
            <!-- END:: CHAT INPUT -->
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING EMOJI PICKER
// import EmojiPicker from "vue-emoji-picker";
// END:: IMPORTING EMOJI PICKER

// START:: IMPORTING SOCKET
// import Echo from "laravel-echo";
// END:: IMPORTING SOCKET

export default {
    name: "SingleChat",

    // components: {
    //     EmojiPicker,
    // },

    props: {
        productId: {
            type: String,
            required: true,
        },
        userId: {
            type: String,
            required: true,
        },
    },

    computed: {
        // START:: VUEX CHAT DATA
        ...mapGetters("ChatModule", ["singleChatData"]),
        // END:: VUEX CHAT DATA
    },

    data() {
        return {
            // START:: FILE PREVIEW APPERANCE DATA
            filePreviewIsOpen: false,
            // END:: FILE PREVIEW APPERANCE DATA

            // START:: EMOJIS DATA
            SearchActive: false,
            searchEmoji: "",
            properties: false,
            // END:: EMOJIS DATA

            // START:: MESSAGES DATA
            textMessageContent: "",
            selectedMediaFile: {
                file: null,
                name: null,
                path: null,
                type: null,
            },
            // END:: MESSAGES DATA
        };
    },

    methods: {
        // START:: VUEX CHAT SEND MESSAGE
        ...mapActions("ChatModule", [
            "getChatsList",
            "getSingleChat",
            "sendMessage",
        ]),
        // END:: VUEX CHAT SEND MESSAGE

        // START:: EMOJIS HANDLING METHODS
        toggleClassActive() {
            if (this.SearchActive) {
                this.SearchActive = false;
            } else {
                this.SearchActive = true;
            }
        },
        append(emoji) {
            this.textMessageContent += emoji;
        },
        showProperties() {
            if (this.properties) {
                this.properties = false;
            } else {
                this.properties = true;
            }
        },
        // END:: EMOJIS HANDLING METHODS

        // START:: CLOSE FILE PREVIEW
        closeFilePreview() {
            this.filePreviewIsOpen = false;

            (this.selectedMediaFile.file = null),
                (this.selectedMediaFile.name = null);
        },
        // END:: CLOSE FILE PREVIEW

        // START:: SELECT MEDIA FILE
        selectMediaFile(e) {
            this.selectedMediaFile.file = e.currentTarget.files[0];
            this.selectedMediaFile.name = e.currentTarget.files[0].name;
            this.selectedMediaFile.path = URL.createObjectURL(
                e.currentTarget.files[0]
            );
            this.selectedMediaFile.type = e.currentTarget.files[0].type;
            this.filePreviewIsOpen = true;
        },
        // END:: SELECT MEDIA FILE

        // START:: SEND MESSAGE
        sendChatMessage() {
            // ********** START:: SEND MESSAGE ********** //
            this.sendMessage({
                productId: this.productId,
                receiverId: this.userId,
                messageType: "text",
                messageContent: this.textMessageContent,
            });
            this.textMessageContent = "";
            // ********** END:: SEND MESSAGE ********** //
        },
        // END:: SEND MESSAGE
    },

    created() {
        // START:: FIRE METHODS
        this.getSingleChat({
            productId: this.productId,
            receiverId: this.userId,
        });
        // END:: FIRE METHODS
    },

    mounted() {
        // START:: FIRE METHODS
        // this.runSocketOnVuex(this.receiverId);
        // END:: FIRE METHODS
    },
};
</script>
