<template>
    <div>
        <!-- START:: FORM -->
        <div class="form_wrapper">
            <!-- ********** START:: FORM TITLE ********** -->
            <div class="form_title_wrapper">
                <h2 class="form_title">{{ $t("TITLES.login") }}</h2>
            </div>
            <!-- ********** END:: FORM TITLE ********** -->

            <!-- ********** START:: FORM ********** -->
            <form @submit.prevent="validateFormInputs">
                <!-- START:: USERNAME INPUT GROUP -->
                <div
                    class="input_wrapper animated_placeholder"
                    :class="{ not_empty_field: data.emailOrPhone }"
                >
                    <input
                        type="text"
                        class="form-control"
                        v-model.trim="data.emailOrPhone"
                    />
                    <label class="form-label">
                        {{ $t("PLACEHOLDERS.emailOrPhone") }}
                    </label>
                </div>
                <!-- END:: USERNAME INPUT GROUP -->

                <!-- START:: PASSWORD INPUT GROUP -->
                <div
                    class="
                        input_wrapper
                        animated_placeholder
                        password_input_wrapper
                    "
                    :class="{ not_empty_field: data.emailOrPhone }"
                >
                    <input
                        type="password"
                        class="form-control"
                        v-model.trim="data.password"
                    />
                    <label class="form-label">
                        {{ $t("PLACEHOLDERS.password") }}
                    </label>
                    <button
                        type="button"
                        class="password_visibility_toggler"
                        @click="togglePasswordVisibility"
                    >
                        <i class="fal fa-eye"></i>
                        <i class="fal fa-eye-slash"></i>
                    </button>
                </div>
                <!-- END:: PASSWORD INPUT GROUP -->

                <!-- START:: FORGET PASSWORD ROUTE WRAPPER -->
                <router-link
                    to="/auth/reset-password-phone-number"
                    class="forget_pass_route"
                >
                    {{ $t("BUTTONS.forgetPassword") }}
                </router-link>
                <!-- END:: FORGET PASSWORD ROUTE WRAPPER -->

                <!-- START:: SUBMIT BUTTON WRAPPER -->
                <div class="btn_wrapper">
                    <button>
                        {{ $t("BUTTONS.login") }}
                        <span class="btn_loader" v-if="isWaitingRequest"></span>
                    </button>
                </div>
                <!-- END:: SUBMIT BUTTON WRAPPER -->

                <!-- START:: LOGIN ROUTE WRAPPER -->
                <router-link to="/auth/register" class="auth_redirect_route">
                    <span>{{ $t("BUTTONS.dontHaveAccount") }}</span>
                    <span> {{ $t("BUTTONS.creteOne") }}</span>
                </router-link>
                <!-- END:: LOGIN ROUTE WRAPPER -->
            </form>
            <!-- ********** END:: FORM ********** -->
        </div>
        <!-- END:: FORM -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters, mapActions } from "vuex";
// END:: IMPORTING VUEX HELPERS

export default {
    name: "LoginForm",

    computed: {
        // START:: VUEX GET APP LANG
        ...mapGetters("AppLangModule", ["getAppLocale"]),
        // END:: VUEX GET APP LANG
    },

    data() {
        return {
            // START:: LOADER HANDLING DATA
            isWaitingRequest: false,
            // END:: LOADER HANDLING DATA

            // START:: LOGIN DATA
            data: {
                name: null,
                password: null,
            },
            // END:: LOGIN DATA
        };
    },

    methods: {
        // START:: VUEX SET AUTHENTICATED USER DATA
        ...mapActions("AuthenticationModule", ["setAuthenticatedUserData"]),
        // END:: VUEX SET AUTHENTICATED USER DATA

        // START:: TOGGLE PASSWORD VISIBILITY METHOD
        togglePasswordVisibility(e) {
            let passwordElement = e.currentTarget.parentElement.children[0];
            let passwordTogglerBtn = e.currentTarget;
            if (passwordElement.type == "password") {
                passwordElement.type = "text";
                passwordTogglerBtn.classList.add("password_is_visible");
            } else if (passwordElement.type == "text") {
                passwordElement.type = "password";
                passwordTogglerBtn.classList.remove("password_is_visible");
            }
        },
        // END:: TOGGLE PASSWORD VISIBILITY METHOD

        // START:: VALIDATE LOGIN FORM INPUTS
        validateFormInputs() {
            this.isWaitingRequest = true;

            if (!this.data.name) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.name"),
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (!this.data.password) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.password"),
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else if (this.data.password.length < 6) {
                this.isWaitingRequest = false;
                this.$iziToast.error({
                    timeout: 2000,
                    message: this.$t("VALIDATION.password_length"),
                    position: this.$t("iziToastConfigs.position"),
                    rtl: this.$t("iziToastConfigs.dir"),
                });
                return;
            } else {
                this.submitForm();
            }
        },
        // END:: VALIDATE LOGIN FORM INPUTS

        // START:: SUBMIT LOGIN FORM
        submitForm() {
            this.isWaitingRequest = true;

            const request_data = new FormData();
            // START:: APPEND STATIC DATA
            request_data.append("type", "ios");
            request_data.append("device_token", "static_device_token");
            // END:: APPEND STATIC DATA

            // START:: APPEND STATIC DATA
            request_data.append("username", this.data.name);
            request_data.append("password", this.data.password);
            // END:: APPEND STATIC DATA

            // START:: SEND REQUEST
            this.$axios({
                method: "POST",
                url: `auth/login`,
                data: request_data,
            })
                .then((res) => {
                    this.isWaitingRequest = false;
                    this.$iziToast.success({
                        timeout: 2000,
                        message: this.$t("VALIDATION.login_success"),
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                    this.setAuthenticatedUserData({
                        id: res.data.data.id,
                        type: res.data.data.user_type,
                        token: res.data.data.token,
                        avatar: res.data.data.image,
                        name: res.data.data.username,
                    });
                    this.clearFormInputs();
                    this.$router.replace("/");
                    location.reload();
                })
                .catch(() => {
                    this.isWaitingRequest = false;
                    this.$iziToast.error({
                        timeout: 2000,
                        message: this.$t("VALIDATION.login_failed"),
                        position: this.$t("iziToastConfigs.position"),
                        rtl: this.$t("iziToastConfigs.dir"),
                    });
                });
            // START:: SEND REQUEST
        },
        // END:: SUBMIT LOGIN FORM

        // START:: CLEAR LOGIN FORM DATA
        clearFormInputs() {
            this.data.name = null;
            this.data.password = null;
        },
        // END:: CLEAR LOGIN FORM DATA
    },
};
</script>
