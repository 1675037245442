import Vue from "vue";
import VueRouter from "vue-router";

// Start:: Importing Middleware
// import auth from "../middleware/auth.js";
// End:: Importing Middleware

// Start:: Importing Router Components
// ============== Start:: Authentications Routes
import Authentication from "../pages/Authentication.vue";
import LoginForm from "../components/authentication/LoginForm.vue";
import ResetPasswordPhoneNumberForm from "../components/authentication/ResetPasswordPhoneNumberForm.vue";
import PhoneVerification from "../components/authentication/PhoneVerificationForm.vue";
import ChangePhoneNumberForm from "../components/authentication/ChangePhoneNumber.vue";
import ResetPasswordForm from "../components/authentication/ResetPasswordForm.vue";
import RegisterForm from "../components/authentication/RegisterForm.vue";
// ============== End:: Authentications Routes

// ============== Start:: Content Wrappers Routes
import AppContentWrapper from "../pages/AppContentWrapper.vue";
import RestaurantDetailsContentWrapper from "../pages/RestaurantDetailsContentWrapper.vue";
// ============== End:: Content Wrappers Routes

// ============== Start:: Qr View Routes
import QrRedirectView from "../views/qrRedirectView/QrRedirectView.vue";
// ============== End:: Qr View Routes

// ============== Start:: Home Page Routes
import HomeContent from "../views/home/HomeContent.vue";
// ============== End:: Home Page Routes

// ============== Start:: Restaurant Details Routes
import RestaurantDetails from "../views/restaurantDetails/RestaurantDetails.vue";
import RestaurantMenu from "../views/restaurantDetails/RestaurantMenu.vue";
// ============== End:: Restaurant Details Routes

// ============== Start:: Carts Routes
import TheCart from "../views/cart/TheCart.vue";
// ============== End:: Carts Routes

// ============== Start:: Chats Routes
import ChatHome from "../views/chat/ChatHome.vue";
import AllChats from "../views/chat/AllChats.vue";
import SingleChat from "../views/chat/SingleChat.vue";
// ============== End:: Chats Routes

// ============== Start:: Static Pages Routes
import AboutUsPage from "../pages/staticPages/AboutUsPage.vue";
import ContactUsPage from "../pages/staticPages/ContactUsPage.vue";
import PrivacyPolicyPage from "../pages/staticPages/PrivacyPolicyPage.vue";
import TermsAndConditionsPage from "../pages/staticPages/TermsAndConditionsPage.vue";
import Faqs from "../pages/staticPages/Faqs.vue";
// ============== End:: Static Pages Routes

// ============== Start:: Not Found Page Routes
import NotFoundPage from "../pages/staticPages/NotFound.vue";
// ============== End:: Not Found Page Routes
// End:: Importing Router Components

Vue.use(VueRouter);
const routes = [
    // ***************** Start:: Auth Routes Config
    {
        path: "/auth",
        component: Authentication,
        children: [
            {
                path: "login",
                components: {
                    large_view: AboutUsPage,
                    small_view: LoginForm,
                },
            },
            {
                path: "reset-password-phone-number",
                name: "ResetPasswordPhoneNumberForm",
                component: ResetPasswordPhoneNumberForm,
            },
            {
                path: "phone-verification/:verificationType",
                name: "PhoneVerification",
                component: PhoneVerification,
                props: true,
            },
            {
                path: "chang-phone-number",
                name: "ChangePhoneNumberForm",
                component: ChangePhoneNumberForm,
            },
            {
                path: "reset-password",
                name: "ResetPasswordForm",
                component: ResetPasswordForm,
            },
            {
                path: "register",
                name: "RegisterForm",
                component: RegisterForm,
            },
        ],
    },
    // ***************** End:: Auth Routes Config

    {
        path: "/qr/:keyword",
        name: "QrRedirectView",
        component: QrRedirectView,
        props: true,
    },

    // ***************** Start:: Home Routes Config
    {
        path: "/",
        component: AppContentWrapper,
        children: [
            // ***************** Start:: Home Page Routes Config
            {
                path: "/",
                components: {
                    large_view: HomeContent,
                    small_view: TheCart,
                },
            },
            // ***************** End:: Home Page Routes Config

            // ***************** START:: CHATS ROUTE CONFIG
            {
                path: "/chats",
                component: ChatHome,
                children: [
                    {
                        path: "",
                        component: AllChats,
                    },
                    {
                        path: "/chats/:productId/:userId",
                        name: "SingleChat",
                        component: SingleChat,
                        props: true,
                    },
                ],
            },
            // ***************** END:: CHATS ROUTE CONFIG

            // ***************** START:: STATIC PAGES ROUTES CONFIG
            {
                path: "/about-us",
                name: "AboutUsPage",
                component: AboutUsPage,
            },
            {
                path: "/contact-us",
                name: "ContactUsPage",
                component: ContactUsPage,
            },
            {
                path: "/privacy-policy",
                name: "PrivacyPolicy",
                component: PrivacyPolicyPage,
            },
            {
                path: "/terms",
                name: "TermsAndConditions",
                component: TermsAndConditionsPage,
            },
            {
                path: "/faqs",
                name: "Faqs",
                component: Faqs,
            },
            // ***************** END:: STATIC PAGES ROUTES CONFIG
        ],
    },
    // ***************** End:: Home Routes Config

    // ***************** Start:: Restaurant Details Routes Config
    {
        path: "/restaurant-details",
        component: RestaurantDetailsContentWrapper,
        children: [
            // ***************** Start:: Home Page Routes Config
            {
                path: ":id",
                components: {
                    large_view: RestaurantDetails,
                    small_view: TheCart,
                },
                children: [
                    {
                        path: "",
                        name: "RestaurantMenu",
                        component: RestaurantMenu,
                    },
                ],
            },
            // ***************** End:: Home Page Routes Config
        ],
    },
    // ***************** End:: Restaurant Details Routes Config

    // ***************** Start:: Not Found Route Config
    {
        path: "/:notFound(.*)",
        name: "NotFoundPage",
        component: NotFoundPage,
    },
    // ***************** End:: Not Found Route Config
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior(to) {
        document.getElementById("app").scrollIntoView({ behavior: "smooth" });
        if (to.hash) {
            return {
                selector: to.hash,
            };
        }
    },
    routes,
});

// START:: MIDDLEWARE AND ROUTER GUARDS
// function nextFactory(context, middleware, index) {
//     const subsequentMiddleware = middleware[index];
//     if (!subsequentMiddleware) return context.next;

//     return (...parameters) => {
//         context.next(...parameters);
//         const nextMiddleware = nextFactory(context, middleware, index + 1);
//         subsequentMiddleware({ ...context, next: nextMiddleware });
//     };
// }

// router.beforeEach((to, from, next) => {
//     if (to.meta.middleware) {
//         const middleware = Array.isArray(to.meta.middleware)
//             ? to.meta.middleware
//             : [to.meta.middleware];

//         const context = {
//             from,
//             next,
//             router,
//             to,
//         };
//         const nextMiddleware = nextFactory(context, middleware, 1);
//         return middleware[0]({ ...context, next: nextMiddleware });
//     } else if (localStorage.getItem("mjrb_app_user_token")) {
//         if (
//             to.name == "LoginForm" ||
//             to.name == "ResetPasswordPhoneNumberForm" ||
//             to.name == "PhoneVerification" ||
//             to.name == "ChangePhoneNumberForm" ||
//             to.name == "ResetPasswordForm" ||
//             to.name == "SelectRegisterationType" ||
//             to.name == "RegisterFirstStepForm"
//         ) {
//             return next("/");
//         } else {
//             return next();
//         }
//     }
//     return next();
// });
// END:: MIDDLEWARE AND ROUTER GUARDS

export default router;
