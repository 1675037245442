<template>
    <div class="stories_wrapper position-relative">
        <!-- Start:: Coming Soon Overlay -->
        <ComingSoonOverlay />
        <!-- End:: Coming Soon Overlay -->

        <!-- Start:: Title Wrapper -->
        <div class="section_title_wrapper">
            <h3 class="section_title">{{ $t("TITLES.trendingStories") }}</h3>
        </div>
        <!-- End:: Title Wrapper -->

        <!-- Start:: Stories Carousel -->
        <div class="stories_carousel" dir="ltr">
            <StoriesCarousel
                :nav="true"
                :dots="false"
                :items="4"
                :margin="15"
                :loop="false"
                :autoplay="false"
                :autoplayTimeout="4000"
                :dragEndSpeed="1000"
                :smartSpeed="1000"
                :responsive="{
                    0: {
                        margin: 8,
                        items: 2,
                        nav: false,
                    },
                    750: {
                        items: 5,
                        nav: false,
                    },
                    1100: {
                        items: 6,
                    },
                }"
            >
                <div
                    class="carousel_item_wrapper"
                    v-for="item in 10"
                    :key="item"
                >
                    <button class="story_btn">
                        <img
                            class="story_image"
                            src="@/assets/media/images/story.svg"
                            alt="story"
                            width="100"
                            height="220"
                        />
                    </button>
                </div>
            </StoriesCarousel>
        </div>
        <!-- End:: Stories Carousel -->
    </div>
</template>

<script>
// Start:: Importing Coming Soon Overlay
import ComingSoonOverlay from "@/components/ui/ComingSoonOverlay.vue";
// End:: Importing Coming Soon Overlay

// Start:: Importing Owl Carousel
import StoriesCarousel from "vue-owl-carousel";
// End:: Importing Owl Carousel

export default {
    name: "StoriesSection",

    components: {
        ComingSoonOverlay,
        StoriesCarousel,
    },
};
</script>
