<template>
    <div class="app_structure_wrapper">
        <!-- Start:: Header -->
        <TheHeader />
        <!-- End:: Header -->

        <!-- Start:: Home Content Wrapper -->
        <div class="row justify-content-center">
            <!-- ********** Start:: Large Content Wrapper ********** -->
            <div
                id="large_content_wrapper"
                :class="isReSized == false ? 'col-lg-8' : 'col-lg-7'"
            >
                <div class="content_wrapper_card">
                    <transition name="fadeInUp">
                        <router-view name="large_view" />
                    </transition>
                </div>
            </div>
            <!-- ********** End:: Large Content Wrapper ********** -->

            <!-- ********** Start:: Small Content Wrapper ********** -->
            <div
                id="small_content_wrapper"
                :class="isReSized == false ? 'col-lg-4' : 'col-lg-5'"
                class="position-relative px-0"
            >
                <!-- Start:: Small Content Wrapper Upper Par -->
                <div class="small_content_upper_par_wrapper">
                    <!-- START:: CART ROUTE -->
                    <router-link
                        to="/cart"
                        class="upper_par_item"
                        :disabled="
                            !getAuthenticatedUserData.token ? 'disabled' : ''
                        "
                    >
                        <img
                            :src="
                                getAppTheme == 'light_theme'
                                    ? require('@/assets/media/icons/ui_icons/cart.svg')
                                    : require('@/assets/media/icons/ui_icons/cart_white.svg')
                            "
                            alt="cart"
                            width="26"
                            height="26"
                        />
                        <span class="toolTip">
                            {{ $t("TOOLTIPS.cart") }}
                        </span>
                    </router-link>
                    <!-- END:: CART ROUTE -->

                    <!-- START:: WISHLIST ROUTE -->
                    <router-link
                        to="/wishlist"
                        class="upper_par_item"
                        :disabled="
                            !getAuthenticatedUserData.token ? 'disabled' : ''
                        "
                    >
                        <img
                            :src="
                                getAppTheme == 'light_theme'
                                    ? require('@/assets/media/icons/ui_icons/heart.svg')
                                    : require('@/assets/media/icons/ui_icons/heart_white.svg')
                            "
                            alt="heart"
                            width="26"
                            height="26"
                        />
                        <span class="toolTip">
                            {{ $t("TOOLTIPS.wishlist") }}
                        </span>
                    </router-link>
                    <!-- END:: WISHLIST ROUTE -->

                    <!-- START:: ADDRESSES ROUTE -->
                    <router-link
                        to="/addresses"
                        class="upper_par_item"
                        :disabled="
                            !getAuthenticatedUserData.token ? 'disabled' : ''
                        "
                    >
                        <img
                            :src="
                                getAppTheme == 'light_theme'
                                    ? require('@/assets/media/icons/ui_icons/location.svg')
                                    : require('@/assets/media/icons/ui_icons/location_white.svg')
                            "
                            alt="location"
                            width="26"
                            height="26"
                        />
                        <span class="toolTip">
                            {{ $t("TOOLTIPS.addresses") }}
                        </span>
                    </router-link>
                    <!-- END:: ADDRESSES ROUTE -->

                    <!-- START:: STORIES ARCHIVE ROUTE -->
                    <router-link
                        to="/stories-archive"
                        class="upper_par_item"
                        :disabled="
                            !getAuthenticatedUserData.token ? 'disabled' : ''
                        "
                    >
                        <img
                            :src="
                                getAppTheme == 'light_theme'
                                    ? require('@/assets/media/icons/ui_icons/box.svg')
                                    : require('@/assets/media/icons/ui_icons/box_white.svg')
                            "
                            alt="box"
                            width="26"
                            height="26"
                        />
                        <span class="toolTip">
                            {{ $t("TOOLTIPS.storiesArchive") }}
                        </span>
                    </router-link>
                    <!-- END:: STORIES ARCHIVE ROUTE -->

                    <!-- START:: RESERVATIONS ROUTE -->
                    <router-link
                        to="/reservations"
                        class="upper_par_item"
                        :disabled="
                            !getAuthenticatedUserData.token ? 'disabled' : ''
                        "
                    >
                        <img
                            :src="
                                getAppTheme == 'light_theme'
                                    ? require('@/assets/media/icons/ui_icons/table.svg')
                                    : require('@/assets/media/icons/ui_icons/table_white.svg')
                            "
                            alt="table"
                            width="42"
                            height="42"
                        />
                        <span class="toolTip">
                            {{ $t("TOOLTIPS.reservations") }}
                        </span>
                    </router-link>
                    <!-- END:: RESERVATIONS ROUTE -->
                </div>
                <!-- End:: Small Content Wrapper Upper Par -->

                <!-- Start:: Small Content-->
                <div class="content_wrapper_card small_content_card">
                    <transition name="fadeInUp">
                        <router-view name="small_view" />
                    </transition>
                </div>
                <!-- End:: Small Content-->
            </div>
            <!-- ********** End:: Small Content Wrapper ********** -->
        </div>
        <!-- End:: Home Content Wrapper -->

        <!-- Start:: Footer -->
        <TheFooter />
        <!-- End:: Footer -->
    </div>
</template>

<script>
// Start:: Importing Vuex Helpers
import { mapGetters } from "vuex";
// End:: Importing Vuex Helpers

// Start:: Importing Layouts
import TheHeader from "@/components/layouts/TheHeader.vue";
import TheFooter from "@/components/layouts/TheFooter.vue";
// End:: Importing Layouts

export default {
    name: "RestaurantDetailsContentWrapper",

    components: {
        TheHeader,
        TheFooter,
    },

    computed: {
        // Start:: Vuex Get App Theme
        ...mapGetters("AppThemeModule", ["getAppTheme"]),
        // End:: Vuex Get App Theme

        // Start:: Vuex Get Authenticated User Data
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // End:: Vuex Get Authenticated User Data
    },

    data() {
        return {
            // Start:: Resize Data
            isReSized: false,
            // End:: Resize Data
        };
    },

    methods: {
        // Start:: Resize Content Wrapper
        onResize() {
            if (window.innerWidth < 994) {
                this.isReSized = true;
            }
        },
        // End:: Resize Content Wrapper

        // Start:: Handel Media Queries
        handleMediaQueries() {
            if (window.matchMedia("(max-width: 840px)").matches) {
                if (
                    this.$router.history.current.matched[1].path ==
                    "/restaurant-details/:id"
                ) {
                    document.getElementById(
                        "small_content_wrapper"
                    ).style.display = "none";
                    document.getElementById(
                        "large_content_wrapper"
                    ).style.display = "block";
                }
            }
        },
        // End:: Handel Media Queries
    },

    mounted() {
        // Start:: Fire Methods
        this.handleMediaQueries();
        // End:: Fire Methods
    },

    created() {
        // Start:: Fire Methods
        this.onResize();
        // End:: Fire Methods
    },
};
</script>
