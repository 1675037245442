<template>
    <div class="offers_panner_wrapper">
        <img :src="pannerImage" alt="offer" width="300" height="300" />
        <div class="offer_panner_overlay">
            <router-link to="/all-offers" class="all_offer_route">
                <div class="text">
                    {{ $t("BUTTONS.seeAllOffers") }}
                </div>

                <div class="icon">
                    <i
                        class="fal fa-angle-double-right"
                        v-show="getAppLocale == 'en'"
                    ></i>
                    <i
                        class="fal fa-angle-double-left"
                        v-show="getAppLocale == 'ar'"
                    ></i>
                </div>
            </router-link>

            <button class="order_now">
                {{ $t("BUTTONS.orderNow") }}
            </button>
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

export default {
    name: "OffersPanner",

    props: {
        pannerImage: {
            type: String,
            require: true,
        },
    },

    computed: {
        // START:: VUEX GET APP LANG
        ...mapGetters("AppLangModule", ["getAppLocale"]),
        // END:: VUEX GET APP LANG
    },
};
</script>
