var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"site_navbar",attrs:{"id":"navbar"}},[_c('div',{staticClass:"logo_and_routes_wrapper"},[_c('router-link',{staticClass:"logo_wrapper",attrs:{"to":"/"}},[_c('img',{attrs:{"src":_vm.getAppTheme == 'light_theme'
                        ? require('@/assets/media/logo/logo.svg')
                        : require('@/assets/media/logo/logo_white.svg'),"alt":"mjrb","width":"60","height":"60"}})]),_c('div',{staticClass:"routes_wrapper"},[_c('NavbarRoutes',{attrs:{"listClassName":"routes_list","listItemClassName":"single_route"}})],1)],1),_c('div',{staticClass:"navbar_actions_btns_wrapper"},[_c('form',[_c('div',{staticClass:"search_input_wrapper"},[_c('input',{attrs:{"type":"text","placeholder":_vm.$t('PLACEHOLDERS.search')}}),_c('button',{staticClass:"search_btn",attrs:{"type":"button"}},[_c('img',{attrs:{"src":_vm.getAppTheme == 'light_theme'
                                ? require('@/assets/media/icons/ui_icons/search.svg')
                                : require('@/assets/media/icons/ui_icons/search_white.svg'),"alt":"search","width":"22","height":"22"}})])])]),(_vm.getAuthenticatedUserData.token)?_c('a-badge',{attrs:{"count":4,"overflow-count":9}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('router-link',_vm._g(_vm._b({staticClass:"notifications_route",attrs:{"to":"/notifications"}},'router-link',attrs,false),on),[_c('img',{attrs:{"src":_vm.getAppTheme == 'light_theme'
                                    ? require('@/assets/media/icons/ui_icons/bell.svg')
                                    : require('@/assets/media/icons/ui_icons/bell_white.svg'),"alt":"chat","width":"22","height":"22"}})])]}}],null,false,688433584)},[_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.notifications"))+" ")])])],1):_vm._e(),(_vm.getAuthenticatedUserData.token)?_c('a-badge',{attrs:{"count":10,"overflow-count":9}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"chats_btn"},'button',attrs,false),on),[_c('img',{attrs:{"src":_vm.getAppTheme == 'light_theme'
                                    ? require('@/assets/media/icons/ui_icons/chat.svg')
                                    : require('@/assets/media/icons/ui_icons/chat_white.svg'),"alt":"chat","width":"22","height":"22"}})])]}}],null,false,3532283746)},[_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.chats"))+" ")])])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"theme_changer",on:{"click":_vm.changeAppTheme}},'button',attrs,false),on),[_c('img',{attrs:{"src":_vm.getAppTheme == 'light_theme'
                                ? require('@/assets/media/icons/ui_icons/sun.svg')
                                : require('@/assets/media/icons/ui_icons/moon.svg'),"alt":"Theme","width":"25","height":"25"}})])]}}])},[_c('span',{staticClass:"toolTip"},[_vm._v(" "+_vm._s(_vm.$t("TOOLTIPS.changeTheme"))+" ")])]),_c('div',{staticClass:"lang_toggler_wrapper"},[(_vm.getAppLocale == 'ar')?_c('button',{staticClass:"lang_toggeler_btn",on:{"click":function($event){return _vm.changeAppLanguage('en')}}},[_c('img',{attrs:{"src":require("../../assets/media/images/flags/en.png"),"alt":"English","width":"40","height":"40"}})]):_c('button',{staticClass:"lang_toggeler_btn",on:{"click":function($event){return _vm.changeAppLanguage('ar')}}},[_c('img',{attrs:{"src":require("../../assets/media/images/flags/ar.png"),"alt":"Arabic","width":"40","height":"40"}})])]),_c('div',{staticClass:"wrapper"},[(!_vm.getAuthenticatedUserData.token)?_c('a',{staticClass:"auth_route",attrs:{"href":"https://www.bymjrb.com/provider/auth/register"}},[_vm._v(" "+_vm._s(_vm.$t("BUTTONS.registerAsProvide"))+" ")]):_vm._e()]),(_vm.getAuthenticatedUserData.token)?_c('div',{staticClass:"user_info_btn_content_wrapper"},[_c('button',{staticClass:"user_info_btn",on:{"click":function($event){$event.stopPropagation();return _vm.toggleUserOptionsMenu.apply(null, arguments)}}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"icon_wrapper",class:{ active: _vm.userOptionsMenuIsOpen }},[_c('img',{attrs:{"src":_vm.getAppTheme == 'light_theme'
                                ? require('@/assets/media/icons/ui_icons/arrow_down.svg')
                                : require('@/assets/media/icons/ui_icons/arrow_down_white.svg'),"alt":"User Image","width":"50","height":"50"}})])]),_c('transition',{attrs:{"name":"fadeInUp"}},[(_vm.userOptionsMenuIsOpen)?_c('div',{staticClass:"user_info_menu_wrapper"},[_c('ul',{staticClass:"user_options_menu"},[_c('li',{staticClass:"menu_item",on:{"click":function($event){$event.stopPropagation();return _vm.toggleUserOptionsMenu.apply(null, arguments)}}},[_c('router-link',{attrs:{"to":"/profile"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.myProfile"))+" ")])],1),_c('li',{staticClass:"menu_item",on:{"click":function($event){$event.stopPropagation();return _vm.toggleUserOptionsMenu.apply(null, arguments)}}},[_c('router-link',{attrs:{"to":"/settings"}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.settings"))+" ")])],1),_c('li',{staticClass:"menu_item",on:{"click":function($event){$event.stopPropagation();_vm.toggleUserOptionsMenu();
                                _vm.logout();}}},[_c('button',[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.logout"))+" ")])])])]):_vm._e()])],1):_vm._e(),_c('button',{staticClass:"small_screens_navbar_toggeler",on:{"click":_vm.toggleSmallScreensNavBar}},[_c('i',{staticClass:"fad fa-bars"})])],1),_c('div',{staticClass:"small_screens_navbar_wrapper",class:{ active: _vm.smallScreensNavbarIsOpen }},[_c('button',{staticClass:"close_btn",on:{"click":_vm.toggleSmallScreensNavBar}},[_c('i',{staticClass:"fal fa-times"})]),_c('NavbarRoutes',{attrs:{"listClassName":"small_screens_links","viewPort":"responsive"},on:{"click":_vm.toggleSmallScreensNavBar}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"img_wrapper"},[_c('img',{attrs:{"src":require("@/assets/media/images/user_avatar.png"),"alt":"User Image","width":"45","height":"45"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info_wrapper"},[_c('div',{staticClass:"name"},[_vm._v("Abdulla Ahmed")]),_c('div',{staticClass:"phone"},[_vm._v("+971 2147 8541 212")])])}]

export { render, staticRenderFns }