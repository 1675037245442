<template>
    <div class="restaurant_stories_wrapper position-relative">
        <!-- Start:: Coming Soon Overlay -->
        <ComingSoonOverlay />
        <!-- End:: Coming Soon Overlay -->

        <!-- Start:: Stories Carousel -->
        <div class="stories_carousel" dir="ltr">
            <StoriesCarousel
                :nav="true"
                :dots="false"
                :items="4"
                :margin="15"
                :loop="false"
                :autoplay="false"
                :autoplayTimeout="4000"
                :dragEndSpeed="1000"
                :smartSpeed="1000"
                :responsive="{
                    0: {
                        items: 2,
                        nav: false,
                    },
                    750: {
                        items: 3,
                        nav: false,
                    },
                    900: {
                        items: 4,
                    },
                    1100: {
                        items: 6,
                    },
                }"
            >
                <div class="carousel_item_wrapper">
                    <button class="story_btn">
                        <img
                            class="story_image"
                            src="@/assets/media/images/addStory.svg"
                            alt="addStory"
                            width="110"
                            height="230"
                        />
                    </button>
                </div>

                <div
                    class="carousel_item_wrapper"
                    v-for="item in 10"
                    :key="item"
                >
                    <button class="story_btn">
                        <img
                            class="story_image"
                            src="@/assets/media/images/story.svg"
                            alt="story"
                            width="100"
                            height="220"
                        />
                    </button>
                </div>
            </StoriesCarousel>
        </div>
        <!-- End:: Stories Carousel -->
    </div>
</template>

<script>
// Start:: Importing Coming Soon Overlay
import ComingSoonOverlay from "@/components/ui/ComingSoonOverlay.vue";
// End:: Importing Coming Soon Overlay

// Start:: Importing Owl Carousel
import StoriesCarousel from "vue-owl-carousel";
// End:: Importing Owl Carousel

export default {
    name: "RestaurantsStories",

    components: {
        ComingSoonOverlay,
        StoriesCarousel,
    },

    props: {
        sectionTitle: {
            type: String,
            required: false,
        },
    },
};
</script>
