<template>
    <div class="restaurant_internal_routes">
        <router-link :to="`/restaurant-details/${branchId}`">
            {{ $t("BUTTONS.menu") }}
        </router-link>

        <router-link :to="`/restaurant-details/${branchId}/overview`" disabled>
            {{ $t("BUTTONS.overview") }}
        </router-link>

        <router-link
            :to="`/restaurant-details/${branchId}/book-table`"
            disabled
        >
            {{ $t("BUTTONS.bookTable") }}
        </router-link>

        <router-link :to="`/restaurant-details/${branchId}/reviews`" disabled>
            {{ $t("BUTTONS.reviews") }}
        </router-link>

        <router-link :to="`/restaurant-details/${branchId}/gallery`" disabled>
            {{ $t("BUTTONS.gallery") }}
        </router-link>
    </div>
</template>

<script>
export default {
    name: "RestaurantInternalRoutes",

    data() {
        return {
            branchId: this.$route.params.id,
        };
    },
};
</script>
