var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"restaurants_section_content_wrapper position-relative"},[_c('div',{staticClass:"section_title_wrapper"},[_c('h3',{staticClass:"section_title"},[_vm._v(_vm._s(_vm.$t("TITLES.categories")))])]),_c('ComingSoonOverlay'),(_vm.categories)?_c('div',{staticClass:"restaurant_categories_carousel",attrs:{"dir":"ltr"}},[_c('CategoriesCarousel',{attrs:{"nav":true,"dots":false,"items":4,"margin":15,"loop":false,"autoplay":false,"autoplayTimeout":4000,"dragEndSpeed":1000,"smartSpeed":1000,"responsive":{
                0: {
                    items: 4,
                    nav: false,
                },
                750: {
                    items: 8,
                    nav: false,
                },
                1100: {
                    items: 10,
                },
            }}},[_c('div',{staticClass:"carousel_item_wrapper"},[_c('button',{staticClass:"category_button active_cat"},[_c('div',{staticClass:"image_wrapper"},[_c('img',{attrs:{"src":require("../../assets/media/icons/ui_icons/restaurants_categories/all.svg"),"alt":"category","width":"30","height":"30"}})]),_c('div',{staticClass:"cat_name"},[_vm._v("All")])])]),_vm._l((_vm.categories),function(item){return _c('div',{key:item.id,staticClass:"carousel_item_wrapper"},[_c('button',{staticClass:"category_button"},[_c('div',{staticClass:"image_wrapper"},[_c('img',{attrs:{"src":item.image,"alt":item.name,"width":"30","height":"30"}})]),_c('div',{staticClass:"cat_name"},[_vm._v(_vm._s(item.name))])])])})],2)],1):_vm._e(),_c('div',{staticClass:"row"},_vm._l((6),function(item){return _c('div',{key:item,staticClass:"col-md-6 col-xl-4 my-3"},[_c('MealCard')],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }