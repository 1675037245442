<template>
    <div class="restaurant_panner_wrapper">
        <div class="inner_wrapper">
            <!-- ********** Start:: Restaurant Logo ********** -->
            <div class="restaurant_logo">
                <img
                    :src="restaurantData.logo"
                    :alt="restaurantData.name"
                    width="110"
                    height="110"
                    @click="toggleImageModal(restaurantData.logo)"
                />
            </div>
            <!-- ********** End:: Restaurant Logo ********** -->

            <!-- ********** Start:: Restaurant Name & Address ********** -->
            <div class="restaurant_name_and_address">
                <div class="restaurant_name">
                    <h4>{{ restaurantData.name }}</h4>
                    <img
                        src="@/assets/media/icons/mjrb_badge.svg"
                        alt="mjrb badge"
                        width="22"
                        height="22"
                        v-if="restaurantData.is_verified"
                    />
                    <div class="restaurant_rate">
                        <RatingStars :rate="restaurantData.rate" />
                        <span class="rate_value">
                            {{ restaurantData.rate }}
                        </span>
                    </div>
                </div>

                <div class="restaurant_address">
                    <span class="icon">
                        <img
                            src="@/assets/media/icons/ui_icons/circle_location_white.svg"
                            alt="icon"
                            width="22"
                            height="22"
                        />
                    </span>
                    <div class="address" @click="spliceAddress">
                        <template
                            v-if="
                                theAddress.length < 60 &&
                                this.restaurantData.address.length > 60
                            "
                            >.......</template
                        >{{ theAddress }}
                    </div>
                </div>
            </div>
            <!-- ********** End:: Restaurant Name & Address ********** -->
        </div>

        <!-- ********** Start:: Mjrb Rate ********** -->
        <div class="mjrb_rate_and_button">
            <span
                class="open_badge"
                :class="restaurantData.open ? 'opened' : 'closed'"
            >
                <template v-if="restaurantData.open">
                    {{ $t("BUTTONS.isOpen") }}
                </template>
                <template v-else>
                    {{ $t("BUTTONS.isClosed") }}
                </template>
            </span>

            <div class="mjrb_rate">
                <span>
                    {{ $t("TITLES.mjrbRates") }}
                </span>
                <MjrbRate :rate="restaurantData.mjrb_rate" />
            </div>
        </div>
        <!-- ********** End:: Mjrb Rate ********** -->

        <!-- Start:: Image Modal -->
        <ImageModal
            @toggleModal="toggleImageModal"
            :modalIsActive="imageModalIsOpen"
            :imageSrc="qrImageToShow"
        />
        <!-- End:: Image Modal -->
    </div>
</template>

<script>
// Start:: Importing Rating Stars
import RatingStars from "@/components/rates/RatingStars.vue";
import MjrbRate from "@/components/rates/MjrbRate.vue";
// End:: Importing Rating Stars
import ImageModal from "@/components/modals/ImageModal.vue";

export default {
    name: "RestaurantPanner",

    components: {
        RatingStars,
        MjrbRate,
        ImageModal,
    },

    props: {
        restaurantData: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            theAddress: this.restaurantData.address,
            imageModalIsOpen: false,
            qrImageToShow: null,
        };
    },

    methods: {
        spliceAddress() {
            if (this.theAddress.length > 55) {
                this.theAddress = this.restaurantData.address.slice(0, 55);
            } else {
                this.theAddress = this.restaurantData.address;
            }
        },

        toggleImageModal(imgSrc) {
            this.imageModalIsOpen = !this.imageModalIsOpen;
            this.imageModalIsOpen
                ? (this.qrImageToShow = imgSrc)
                : (this.qrImageToShow = null);
        },
    },

    created() {
        // Start:: Fire Methods
        this.spliceAddress();
        // End:: Fire Methods
    },
};
</script>
