<template>
    <div class="meal_details_content_wrapper">
        <!--  Start:: Meal Details Modal -->
        <v-dialog
            v-model="modalIsActive"
            @click:outside="toggleModal"
            max-width="700px"
        >
            <v-card>
                <div class="test_title">Test Modal</div>
            </v-card>
        </v-dialog>
        <!--  End:: Meal Details Modal -->
    </div>
</template>

<script>
export default {
    name: "MealDetailsModal",

    emits: ["toggleModal"],

    props: {
        modalIsActive: {
            type: Boolean,
            required: true,
            default: false,
        },
    },

    methods: {
        // Start:: Toggle Modal
        toggleModal() {
            this.$emit("toggleModal");
        },
        // End:: Toggle Modal
    },
};
</script>

<style lang="scss" scoped></style>
