<template>
    <div>
        <!-- START:: PAGE CONTENT -->
        <div class="about_us_page_content_wrapper"></div>
        <!-- END:: PAGE CONTENT -->
    </div>
</template>

<script>
export default {
    name: "AboutUsPage",

    data() {
        return {
            // START:: HANDLING LOADER APPERANCE
            isLoading: false,
            // END:: HANDLING LOADER APPERANCE

            // START:: ABOUT US DATA
            aboutUsData: null,
            // END:: ABOUT US DATA
        };
    },

    methods: {
        // START:: GET PAGE DATA
        getPageData() {
            // START:: ========== SEND REQUEST ==========
            this.$axios({
                method: "GET",
                url: `about`,
            })
                .then((res) => {
                    this.isLoading = false;
                    this.aboutUsData = res.data.data.about;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.error(error.response.data.message);
                });
            // END:: ========== SEND REQUEST ==========
        },
        // END:: GET PAGE DATA
    },

    created() {
        // START:: FIRE METHODS
        this.getPageData();
        // END:: FIRE METHODS
    },
};
</script>
