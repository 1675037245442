<template>
    <div class="home_page_content_wrapper">
        <!-- Start:: Offer Panner -->
        <OfferPanner
            :pannerImage="
                require('@/assets/media/images/panners/offerPanner.png')
            "
        />
        <!-- End:: Offer Panner -->

        <!-- Start:: Meals Categories -->
        <MealsCategories :categories="homeCategories" v-if="homeCategories" />
        <!-- End:: Meals Categories -->

        <!-- Start:: Trending Stories -->
        <StoriesSection />
        <!-- End:: Trending Stories -->

        <!-- Start:: Super Restaurants -->
        <SuperRestaurants
            :restaurants="homeRestaurants"
            v-if="homeRestaurants"
        />
        <!-- End:: Super Restaurants -->
    </div>
</template>

<script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyC2MTR_vRbsbQQRPt5f5ZLCvvaKOpzkzlA"></script>
<script>
// Start:: Importing Home Page Components
import OfferPanner from "@/components/panners/OfferPanner.vue";
import MealsCategories from "@/components/general/MealsCategories.vue";
import StoriesSection from "@/components/general/StoriesSection.vue";
import SuperRestaurants from "@/components/general/SuperRestaurants.vue";
// End:: Importing Home Page Components

export default {
    name: "HomeContent",

    components: {
        OfferPanner,
        MealsCategories,
        StoriesSection,
        SuperRestaurants,
    },

    data() {
        return {
            // Start:: Loaders Handling Data
            isWaitingRequest: false,
            // End:: Loaders Handling Data

            // Start:: G-Map Data
            currentCoordinates: {},
            // End:: G-Map Data

            // Start:: Page Data
            homeCategories: null,
            homeRestaurants: null,
            // End:: Page Data
        };
    },

    methods: {
        // Start:: Get Categories
        getCategories() {
            this.isWaitingRequest = true;
            // Start:: Send Request
            this.$axios({
                method: "GET",
                url: `api/meal_categories`,
            })
                .then((res) => {
                    this.isWaitingRequest = false;
                    this.homeCategories = res.data.data;
                })
                .catch((error) => {
                    this.isWaitingRequest = false;
                    this.$message.error(error.response.data.message);
                    if (error.response.status == 401) {
                        this.logout();
                    }
                });
            // End:: Send Request
        },
        // End:: Get Categories

        // Start:: Get Current Location ==> Get Restaurants Based On Current Coordinates
        getCurrentLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.currentCoordinates = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    // ********** Start:: Get  Restaurants With Current Coordinates **********//
                    this.getHomeRestaurants();
                    // ********** End:: Get  Restaurants With Current Coordinates **********//
                });
            }
        },
        // End:: Get Current Location ==> Get Restaurants Based On Current Coordinates

        // Start:: Get Home Restaurants
        getHomeRestaurants() {
            this.isWaitingRequest = true;
            // Start:: Send Request
            this.$axios({
                method: "GET",
                url: `api/home_restaurants`,
                params: {
                    lat: this.currentCoordinates.lat,
                    lng: this.currentCoordinates.lng,
                },
            })
                .then((res) => {
                    this.isWaitingRequest = false;
                    this.homeRestaurants = res.data.data;
                })
                .catch((error) => {
                    this.isWaitingRequest = false;
                    this.$message.error(error.response.data.message);
                    if (error.response.status == 401) {
                        this.logout();
                    }
                });
            // End:: Send Request
        },
        // End:: Get Home Restaurants
    },

    created() {
        // Start:: Fire Methods
        this.getCategories();
        this.getCurrentLocation();

        if (Object.keys(this.currentCoordinates).length == 0) {
            this.getHomeRestaurants();
        }
        // End:: Fire Methods
    },
};
</script>
