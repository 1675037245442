<template>
    <div class="restaurant_details_content_wrapper">
        <!-- Start:: Restaurant Panner -->
        <RestaurantPanner
            :restaurantData="restaurantDetails"
            v-if="restaurantDetails"
        />
        <!-- End:: Restaurant Panner -->

        <!-- Start:: Restaurant Stories -->
        <RestaurantStories />
        <!-- End:: Restaurant Stories -->

        <!-- Start:: Restaurant Internal Routes -->
        <RestaurantInternalRoutes />
        <!-- End:: Restaurant Internal Routes -->

        <!-- Start:: Restaurant Internal Views -->
        <transition name="fadeInUp">
            <router-view />
        </transition>
        <!-- end:: Restaurant Internal Views -->
    </div>
</template>

<script>
// Start:: Importing Home Page Components
import RestaurantPanner from "@/components/panners/RestaurantPanner.vue";
import RestaurantStories from "@/components/restaurants/RestaurantStories.vue";
import RestaurantInternalRoutes from "@/components/restaurants/RestaurantInternalRoutes.vue";
// End:: Importing Home Page Components

export default {
    name: "RestaurantDetails",

    components: {
        RestaurantPanner,
        RestaurantStories,
        RestaurantInternalRoutes,
    },

    data() {
        return {
            // Start:: Loaders Handling Data
            isWaitingRequest: false,
            // End:: Loaders Handling Data

            // Start:: Restaurant Id
            restaurantId: this.$route.params.id,
            // End:: Restaurant Id

            // Start:: Restaurant  Details
            restaurantDetails: null,
            // End:: Restaurant  Details
        };
    },

    methods: {
        // Start:: Get Restaurant Data
        getRestaurantData() {
            // Start:: Send Request
            this.$axios({
                method: "GET",
                url: `api/branch/${this.restaurantId}`,
            })
                .then((res) => {
                    this.isWaitingRequest = false;
                    this.restaurantDetails = res.data.data;
                })
                .catch((error) => {
                    this.isWaitingRequest = false;
                    this.$message.error(error.response.data.message);
                    if (error.response.status == 401) {
                        this.logout();
                    }
                });
            // End:: Send Request
        },
        // End:: Get Restaurant Data
    },

    created() {
        // Start:: Fire Methods
        this.getRestaurantData();
        // End:: Fire Methods
    },
};
</script>
