var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chats_list_wrapper"},[(_vm.chatsListData)?_c('div',{staticClass:"container"},[_c('ul',{staticClass:"chats_list"},_vm._l((_vm.chatsListData),function(item){return _c('li',{key:item.id,staticClass:"chat_route_wrapper"},[_c('button',{staticClass:"chat_route_btn",on:{"click":function($event){return _vm.navigateToChat(item.product_id, item.receiver_id)}}},[_c('div',{staticClass:"conversation_avatar_wrapper"},[_c('img',{attrs:{"src":item.product.main_image,"alt":item.product.name,"width":"90","height":"90"}})]),_c('div',{staticClass:"conversation_details_wrapper"},[_c('div',{staticClass:"name_and_last_message_date_wrapper"},[_c('h5',{staticClass:"name"},[_vm._v(_vm._s(item.product.name))]),_c('h6',{staticClass:"last_message_date_wrapper"},[_vm._v(" "+_vm._s(item.created_at)+" ")])]),_c('h6',{staticClass:"last_message"},[_vm._v(" "+_vm._s(item.last_message)+" ")])])])])}),0),_c('div',{staticClass:"pagination_container text-center my-4"},[_c('v-pagination',{staticClass:"py-0",attrs:{"square":"","length":_vm.chatsListPagenation.lastPage,"total-visible":6,"prev-icon":_vm.getAppLocale == 'ar'
                        ? 'fal fa-angle-right'
                        : 'fal fa-angle-left',"next-icon":_vm.getAppLocale == 'ar'
                        ? 'fal fa-angle-left'
                        : 'fal fa-angle-right'},on:{"input":function($event){return _vm.setSearchResultsByPagination({
                        pageLink: null,
                        selectedPage: _vm.setChatsListByPagination.currentPage,
                    })},"next":function($event){return _vm.setSearchResultsByPagination({
                        pageLink: _vm.setChatsListByPagination.nextPageLink,
                        selectedPage: null,
                    })},"previous":function($event){return _vm.setSearchResultsByPagination({
                        pageLink: _vm.setChatsListByPagination.previousPageLink,
                        selectedPage: null,
                    })}},model:{value:(_vm.chatsListPagenation.currentPage),callback:function ($$v) {_vm.$set(_vm.chatsListPagenation, "currentPage", $$v)},expression:"chatsListPagenation.currentPage"}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }